
.EditorSidebarNavigationContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;   
    width: 15vw;
}

.EditorSidebarNavigationContainer p, h6, a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SidebarTooltip {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.EditorSidebarNavigationContainer .SidebarOptionsCourseName {
    padding-left: 0px;
    text-decoration: none;
    color: inherit;
    max-height: 95%; 
    
  }

.SidebarOptionsCourseName a {
    text-decoration: none;
    color: inherit;
  }

.SidebarOptionsCourseName .Active {
    color: var(--primary-color-3)
}

.SidebarExpandButton{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 10px 0px 0px;
    margin: 0px;
}

.EditorSidebarNavigationContainer .SidebarIcon{
    height: 20px;
    width: 20px;
    padding:2px;
}
.EditorSidebarNavigationContainer .SidebarIconSmall{
    height: 17px;
    width: 17px;
    padding:2px;
}
  
  .SidebarColapseButton{
    position: sticky;
    top: 50%;    
    align-self: right;
    width: 35px;
    height: 35px;        
    background-color:  #D9D9D9;
    border: 0px;
    border-radius: var(--ds-border-radius-circle, 50%);
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color:  #000000;
    cursor: pointer;
    outline: 0px;
    margin-top: 10px;
  }
  
  .SidebarColapseButton .icon-typeChvron-Down {
    display: block;
    transform: rotate(-90deg);
  }

  .SidebarColapseButton .icon-typeChvron-Down.expand {
    transform: rotate(90deg);
  }
  
  .EditorSidebarButtonContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
  }
  
  .SidebarRowContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .SidebarLink{
    display: flex;
    align-items: center;
    gap: 5px;
  }


.EditorSidebarNavigationContainer h6{
    font-family: var(--font-family);
    font-weight: bold;
    font-size: var(--font-size-h6);
    color: var(--neutral-color-1);
    padding: 10px 10px 0px 0px;
}

.EditorSidebarNavigationContainer .SidebarOptionsContainer ul{
    list-style: none;
    
}

.EditorSidebarNavigationContainer .SidebarOptionsContainer ul li{    
    list-style: none;    
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    cursor: pointer;
}


.EditorSidebarButtonContainer .SidebarOptionsContainer span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

@media (min-width: 300px) and (max-width: 1024px) {
  .EditorSidebarNavigationContainer .NavBarRegularButton {
    overflow: visible;
  }
}

