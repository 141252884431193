.TextInputStandart {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-1);
    font-weight: normal;
    border-bottom: 1px solid #efeae7;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}

.TextInputContainer .FieldError {
    border-bottom: 1px solid var(--error-color-1);
}

.TextInputPill {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: #E7ECEF;
    border-radius: 40px;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--primary-color-1);
    font-weight: normal;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.TextInputBox {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: var(--neutral-color-10);
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--primary-color-1);
    font-weight: normal;
    padding: 8px;
    width: 100%;
    margin-top: 5px;
}

.TextInputStandart::placeholder {
    color: var(--neutral-color-1);
    opacity: 50%;
}

.TextInputTitle {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 0px;
    margin-right: 6px;
}

.TextInputEye {
    margin-left: 10px;
}

.TextInputMaxCharacters {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    padding-left: 5px;
    padding-right: 5px;
}

.TextInputMaxCharacters span {
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: #9E9E9E;
    font-weight: normal;
}

.DisabledInput {
    color: var(--neutral-color-4);
}

.TextInputCharactersCountContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.TextInputSubtitle {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    color: var(--neutral-color-5);      
  }