.SecurityUserProfileContainer {
    padding: 2rem;
    min-height: 50vh;
}

.SecurityUserProfileContainer h5 {
    font-family: var(--font-family);
    font-size: var(--font-size-h5);
    color: black;
    font-weight: bold;
    margin-bottom: 6px;
}
  
.SecurityUserProfileContainer .Subtitle {
    font-family: var(--font-family);
    font-size: var(--font-size-1);
    color: var(--neutral-color-3);
    font-weight: normal;
    margin-bottom: 30px;
    display: block;
}

.SecurityUserProfileContainer .SecurityInput {
  margin-top: 20px;
}

.SecurityUserProfileContainer .SecuritySaveButton {
  margin-top: 30px;
}

@media only screen and (min-width: 1025px) {
    .SecurityUserProfileContainer {
      padding: 2rem 13rem 2rem 3rem;
    }
}
