.FileUploadModalBody {
    padding: 0px 60px;
    max-width: 45vw;
}

.FileUploadModalBody h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    color: var(--primary-color-1);
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
}

.FileUploadModalBody .VideoFileContainer {
    flex: 1;
}

.FileUploadModalBody .VideoFileContainer video {
    width: 100%;
    aspect-ratio: 1.77;
    border-radius: 16px;
    background-color: var(--primary-color-1);
}

.FileUploadModalBody .FileTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    color: var(--color-black);
    font-weight: 500;
    overflow: hidden;
    max-width: 20vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 6px;
    margin-bottom: 6px;
}

.FileUploadModalBody .FileResolution {
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: var(--neutral-color-5);
    font-weight: 500;
    margin-bottom: 0px
}

.FileUploadModalBody .PreviewContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
}

.FileUploadModalBody .FileUploadTip {
    flex: 1;
}

.FileUploadModalBody h4 {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-balck);
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
}

.FileUploadModalBody .Footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
