.UserProfileContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 0 10px 0px;
  width: 100%;
  list-style-type: none;
  background-color: var(--color-white);
  min-height: 90vh;
}

.UserProfileContainer button {
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--primary-color-3);
  border: 0.5px solid #cbd1d8;
  min-width: 5px;
}

.UserProfileBanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color-3);
  width: 100%;
  list-style-type: none;
  height: 90px;
}

.UserProfileBanner ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 5%;
}

.UserProfileBanner ul li {
  list-style-type: none;
  padding: 0.3rem;
}

.UserProfileContainer .Avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border: 2px solid #011e1ec4;
}

.UserProfileContainerText {
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-white);
  padding: 0;
  margin: 0;
}

.UserProfileRowBackground {
  background-color: #f5f5f5;
  /* TEMPORAL FIX */
  margin: 0 !important;
  padding-bottom: 2rem;
}

.UserAvatarTextImage {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 40px;
  height: 40px;
}

.UserAvatarTextImage::before {
  content: '';
  border: 2px solid var(--secondary-color-1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}

.UserProfileContentSection {
  flex: 1;
  min-height: 50vh;
}

.UserProfileRowBackground .col-lg-8.col-12{
    padding:0;
  }

