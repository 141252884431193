.LessonsListRoot {
    height: 100%;
}

.LessonsListRoot .EditorContentContainer {
    padding-bottom: 200px;
}

.LessonsInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LessonBlock {
    position: relative;
}

.LessonBlock .SupplementalBlock {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.LessonBlock .SupplementalBlock .Arrow {
    margin-left: 5px;
    margin-right: 5px;
}

.LessonBlock .ArrowPlaceholder {
    height: 51px;
}

.LessonBlock .SupplementalBlock .BalancingBlock {
    visibility: hidden;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.LessonBlock .SupplementalBlock .RetryCard {
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    padding: 3px 5px 3px 5px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: row;
    border-radius: 3px;
}

.LessonBlock .SupplementalBlock .RetryCard span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    color: var(--neutral-color-1);
    line-height: normal;
}

.LessonBlock .SupplementalBlock .RetryCard img {
    margin-right: 5px;
    width: 14px;
    height: 14px;
}

.LessonBlock .BackArrowLarge {
    position: absolute;
    left: 75px;
    top: 20px;
}

.LessonBlock .ArrowContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.LessonCard {
    background: white;
    border-radius: 5px;
    padding: 5px;
    border: 2px solid transparent;
    position: relative;
    border: 2px solid #d3d2d2;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.LessonCard:hover {
    border-color: #9CD8804D;
    box-shadow: 0px 3px 4px 1px #c4c4c4;
}

.AlertCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    border: 2px solid transparent;
    background-color: var(--color-white);
    padding: 15px;
    max-width: 150px;
}

.AlertCard:hover {
    box-shadow: 0 0 0 2px #9CD8804D;
}

.AlertCard p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    color: var(--neutral-color-1);
    text-align: center;
}

.SelectedLessonCard {
    border: 2px solid #9CD880;
}

.LessonCard p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 5px;
    text-align: center;
    max-width: 135px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.LessonCard .ImageContainer {
    width: 135px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neutral-color-10);
}

.LessonCard .ImageContainer .Poster {
    width: 135px;
    height: 90px;
    object-fit: contain;
}

.LessonCard .ImageContainer img {
    object-fit: contain;
}

.LessonCard .BadgesContainer {
    position: absolute;
    left: 5px;
    bottom: 5px;
    display: flex;
    flex-direction: row;
}

.LessonCard .BadgesContainer .Badge {
    padding: 2px;
    background-color: var(--color-white);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
}

.LessonCard .BadgesContainer .Badge span {
    font-family: var(--font-family);
    font-size: 7px;
    font-weight: normal;
    color: var(--primary-color-3);
}

.LessonsOptions .OptionsSection {
    margin-top: 20px;
}

.LessonsOptions .GradeDisclaimer {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-3);
    margin-top: 5px;
}

.RetryCard {
    cursor: pointer;
}

.RetryTooltipArrowDetail:before {
    content: "";
    width: 40px;
    position: absolute;
    height: 40px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    z-index: 10;
    background: white;
    box-shadow: none;
    border: 0;
    border-radius: 5px;
}

.RetryTooltipArrowDetail {
    width: 40px;
    position: absolute;
    height: 24px;
    overflow: hidden;
    left: 5px;
    top: -16px;
}

.RetryTooltipContainer {
    background-color: var(--color-white);
    border: 0 solid #f5f5f5e9;
    border-radius: 5px;
    position: absolute;
    width: 120px;
    top: 170%;
    left: -5px;
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    right: 0px;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 7px;
    padding-right: 7px;
    filter: drop-shadow(0px 1px 5px #D2D2D2);
    z-index: 200;
}

.RetryTooltipContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 10px;
}

.LessonInfoModal {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 90;
}

.LessonInfoModal .InfoOpener {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.LessonInfoModal .InfoBody {
    margin-top: 10px;
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
}

.LessonInfoModal .InfoBody img {
    width: 20px;
    height: 20px;
    object-fit: fill;
    margin-right: 15px;
}

.LessonInfoModal .InfoBody span {
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    font-size: 12px;
    font-weight: normal;
}
