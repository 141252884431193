
.MultiInput{
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    margin-bottom: 10px;
}

.MultiInput h3{
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 15px;
}

.MultiInput input{
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    border-bottom: 1px solid #efeae7;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}

.MultiInput::placeholder {
    color: var(--neutral-color-1)59;
}

.MultiInput .MultiInputTip{
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
}

.MultiInput .react-multi-email {
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.MultiInput .react-multi-email > span[data-placeholder] {
    top: 0.8em;
    left: 0.9em;
    color: var(--neutral-color-7) !important;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
}