.LearnersAiAssistantsContainer {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 800;
}

.LearnersAiAssistantsContainer .AiAssistantChatboxContainer {
    bottom: 140px;
}

.LearnersAiAssistantsContainer .OpenerButton {
    background: var(--gradient-background);
    border: 1px solid var(--accent-color);
    outline: none;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.LearnersAiAssistantsContainer .OpenerButton:hover {
    transform: scale(1.1);
}

.LearnersAiAssistantsContainer .OpenerButton .icon-plus {
    color: var(--accent-color);
    font-size: 24px;
    display: block;
    transform: rotate(45deg);
}

.LearnersAiAssistantsContainer .OpenerButton .icon-ai_assistant_ic {
    color: var(--accent-color);
    font-size: 34px;
    padding-bottom: 12px;
    padding-left: 8px;
}

.LearnersAiAssistantsContainer .AssistantsListContainer {
    background-color: var(--color-white);
    border-radius: 24px;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
    height: 70vh;
    width: 350px;
    margin-bottom: 30px;
    overflow: hidden;
}

.LearnersAiAssistantsContainer .AssistantsListContainer .Header {
    padding: 8px 20px;
    background: var(--gradient-background);
}

.LearnersAiAssistantsContainer .AssistantsListContainer .Header p {
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    margin-bottom: 0;
    font-weight: 500;
    line-height: 24px;
}

.LearnersAiAssistantsContainer .AssistantContainer {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid var(--neutral-color-9);
    background: var(--color-white);
    padding: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.LearnersAiAssistantsContainer .AssistantContainer:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
}

.LearnersAiAssistantsContainer .AssistantContainer img {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    margin-right: 11px;
}

.LearnersAiAssistantsContainer .AssistantContainer .Placeholder {
    background: var(--gradient-background);
    width: 52px;
    height: 52px;
    border-radius: 8px;
    margin-right: 11px;
}

.LearnersAiAssistantsContainer .AssistantContainer .Placeholder span {
    color: var(--accent-color);
    font-size: 34px;
    margin-top: 6px;
    display: block;
    margin-left: 14px;
}

.LearnersAiAssistantsContainer .AssistantContainer .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    font-weight: 500;
    margin-bottom: 0;
}

.LearnersAiAssistantsContainer .AssistantContainer .Description {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    color: var(--neutral-color-3);
    font-weight: 400;
    margin-bottom: 0;
}

.LearnersAiAssistantsContainer .AssistantsList {
    padding: 20px;
    overflow-y: scroll;
    height: 100%;
}