.LoaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.FirstSubPaymentBackButton {
    color: #2B8982;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    padding-left: 0;
    margin-top: 30px;
    background: none;
    outline: none;
    border: none;
}

.FirstSubPaymentBackButton:hover {
    opacity: 0.7;
}

.SubFirstPaymentContainer {
    margin-top: 30px;
    border: 1px solid #D5DBDF;
    padding: 22px 35px;
}

.SubFirstPaymentContainer .PlanTitle {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    background: linear-gradient(317deg, #04504E -11.84%, #022221 110.79%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline var(--accent-color);
    text-decoration-thickness: 6px;
    text-underline-offset: 7px;
    margin-bottom: 14px;
}

.SubFirstPaymentContainer .FirstSubPaymentBackButton span {
    display: flex;
    flex-direction: row;
}

.SubFirstPaymentContainer .Title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 4px;
}

.SubFirstPaymentContainer .Subtitle {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
}

.SubFirstPaymentContainer .InfoTitle {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 8px;
}

.SubFirstPaymentContainer .InfoList {
    padding-left: 1rem;
}

.SubFirstPaymentContainer .InfoText {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
}

.SubFirstPaymentContainer .Divider {
    width: 100%;
    height: 1px;
    background-color: #D5DBDF;
}

.SubFirstPaymentContainer .SummaryText {
    margin-top: 22px;
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 16px;
}

.SubFirstPaymentContainer .SummaryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SubFirstPaymentContainer .SummarySectionTitle {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 8px;
}

.SubFirstPaymentContainer .Features {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0;
    margin-right: 14px;
}

.SubFirstPaymentContainer .Features li::marker {
    display: none;
    content: '';
}

.SubFirstPaymentContainer .Features li {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.SubFirstPaymentContainer .Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.SubFirstPaymentContainer .PaySubButton {
    margin-top: 15px;
    border-radius: 8px;
    border: none;
    outline: none;
    background: linear-gradient(317deg, #04504E -11.84%, #022221 110.79%);
    padding: 10px 20px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.SubFirstPaymentContainer .PaySubButton:hover {
    color: var(--accent-color);
    box-shadow: 0px 4.626px 18.504px 0px rgba(43, 137, 130, 0.28);
}

.SubFirstPaymentContainer .PriceTotal {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    text-align: end;
    margin-top: 12px;
}