.SubscriptionSuccessContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.SubscriptionSuccessContainer .SuccessCheck {
    font-size: 86px;
    background-color: var(--success-color-1);
    color: white;
    border-radius: 86px;
    padding: 5px
}

.SubscriptionSuccessContainer h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    font-weight: bold;
    margin-top: 15px;
}

.SubscriptionSuccessContainer h2 {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    font-weight: normal;
    margin-top: 15px;
}

.SubscriptionSuccessContainer h4 {
    font-family: var(--font-family);
    font-size: var(--font-size-h4);
    font-weight: bold;
}

.SubscriptionSuccessContainer p {
    font-family: var(--font-family);
    font-size: var(--font-size-paragraph);
    font-weight: normal;
    margin-bottom: 5px;
}

.SubscriptionSuccessContainer .InfoBox {
    background-color: var(--neutral-color-10);
    border-radius: 5px;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.SubscriptionSuccessContainer .WorkspaceButton {
    margin-top: 15px;
}