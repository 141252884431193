.ListLearnersContainer {
    margin-top: 20px;
    padding-bottom: 30px;
}

.ListLearnersContainer .NoLearners {
    font-family: var(--font-family);
    font-weight: bold;
    color: #adadad;
    font-size: 0.8rem;
    padding: 4rem 0;
}

.ListLearnersContainer .ControlPanel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ListLearnersContainer .HeaderActionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ListLearnersContainer .ControlPanel .FilterButtonWizard{
    position: relative;
}

.ListLearnersContainer .ControlPanel .FilterButtonWizard .FilterButton{
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}

.ListLearnersContainer .ControlPanel .LearnerPageSwitchButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ListLearnersContainer .AddButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ListLearnersContainer .ControlPanel .FilterSearchAddContainer{
    display: flex;
    flex-direction: row;

}

.ListLearnersContainer .ActivateContainer span {
    color: var(--primary-color-3);
    font-size: 16px;
}

/* .ListLearnersContainer .ControlPanel .SearchAddLearners{
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    gap: 10px;
} */

.SubTable p{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-bottom: 0;    
}

.ListLearnersContainer .ControlPanel .Input{
    margin-right: 10px;
    margin-left: 6px;
}

.ListLearnersContainer .Table {
    width: 95%;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    margin-top: 30px;
}


.ListLearnersContainer .TableDeleteButton {
    background: none;
	color: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    background-color: none;
}

.ListLearnersContainer .TableNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ListLearnersContainer .TableNameContainer img {
    border-radius: 30px;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    object-fit: cover;
}

.ListLearnersContainer .TableText {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.ListLearnersContainer .NoCoursesText {
    padding-top: 20px;
    padding-bottom: 20px;
}

[data-column-id="learner_status"].rdt_TableCol,
[data-column-id="learner_status"].rdt_TableCell
{
    text-align: center;
    width: 100%;
    justify-content: center;
}

.ListLearnersContainer .Table .ActiveLearner {
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    border: 1px solid var(--primary-color-3);
    background: #33C35326;
    color: var(--primary-color-3);
    white-space: nowrap;
}

.ListLearnersContainer .Table .InvitedStatus {
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    border: 1px solid var(--neutral-color-2);
    background: #D3D5D426;
    color: var(--neutral-color-2);
    white-space: nowrap;
}


.ListLearnersContainer .Table .InactiveLearner {
    color: #971B1B;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    border: 1px solid #971B1B;
    background: #D7343426;
    white-space: nowrap;
}

.ListLearnersContainer .Table .idEBuo{
    flex-grow: 0;
    padding: 10px;
}

.ListLearnersContainer .rdt_TableHead {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-5);
    text-align:right;
}

.ListLearnersContainer  .rdtTableCell{
    align-items: right;
    
}
.ExpandedRowTable{
    display: flex;
    flex-direction: row;
}

.ListLearnersContainer .Table .SubTable {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--neutral-color-5);
    padding-left: 100px;
    padding-right: 100px;
    max-width: 80%;
}

.ListLearnersContainer .LearnerAvatarPlaceholder {
    background-color: var(--primary-color-3);
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
    border-radius: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ListLearnersContainer .LearnerAvatarPlaceholder span {
    font-family: var(--font-family);
    font-size: 16px;
    color: #FFFFFF;
    font-weight: normal;
}

.ListLearnersContainer .FilterNames h3 {
    font-family: var(--font-family);
    font-size: 10px;
    color: var(--neutral-color-1);
    font-weight: normal;
}

.ListLearnersContainer

[data-column-id="course_progress"].rdt_TableCell,
[data-column-id="course_finished_lessons"].rdt_TableCell,
[data-column-id="course_actions"].rdt_TableCell
{
    text-align: center;
    width: 100%;
    justify-content: center;
}

[data-column-id="course_progress"].rdt_TableCol_Sortable,
[data-column-id="course_finished_lessons"].rdt_TableCol_Sortable,
[data-column-id="course_actions"].rdt_TableCol_Sortable
{
    text-align: center;
    width: 100%;
    justify-content: center;
}

.ListLearnersContainer .FilterWizardContainer{
    max-width: 350px;
    margin-top: 0.5rem;
}