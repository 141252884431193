.QuestionMarkTooltip {
    display: flex;
}

.QuestionMarkTooltip .icon-typeQuestion {
    background-color: #d2d6db;
    border-radius: 5rem;
    font-size: 20px;
    color: #fff;
}

.TooltipCloud {
    background-color: var(--neutral-color-10);
    padding: 16px;
    border-radius: 4px;
    margin-top: 18px;
    font-family: var(--font-family);
    color: var(--primary-color-2);
    font-size: var(--font-size-5);
    max-width: 25vw;
    outline: 1px solid var(--primary-color-5);
    z-index: 400;
    position: relative;
}

.TooltipCloud .ArrowPosition {
    position: absolute;
    left: 47%;
    top: -12px;
}