.CoursesListContainer {
  padding: 15px 65px;
}

.CoursesListContainer .CoursesEmptyStateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  flex-direction: column;
}

.CoursesListContainer .CoursesEmptyStateContainer img {
  margin-bottom: 22px;
}

.CoursesListContainer .CoursesEmptyStateContainer p {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-7);
  font-weight: 500;
}

.CoursesListContainer .ModalContainer .ModalContent,
.CoursesListContainer .ModalContainer .ModalContent .container {
  padding: 0;
}

.CoursesListContainer .CoursesListHeader {
  display: flex;
}

.CoursesListContainer .CoursesListHeader div:first-child {
  flex: 1;
}

.CoursesListContainer .CoursesListHeader .CourseFiltersContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.CoursesListContainer  .ActionButton {
  border-radius: 12px;
  height: 42px;
}

.CoursesListContainer .ActionButton span {
  display: flex;
  align-items: center;
}

.CoursesListContainer .ActionButton .icon-log-out {
  transform: rotate(-90deg);
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-right: 2px;
}

.CoursesListContainer .CourseCardContainer {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 40px;
}

.CoursesListContainer .CourseCard {
  border: 1px solid;
  border-radius: 4px;
  position: relative;
  border-color: #e5e7ea;
  width: 100%;
  max-width: 306px;
  margin: 10px 0;
  position: relative;
}

.CoursesListContainer .CourseCard .CategoryContainer {
  position: absolute;
  top: 16px;
  left: 0;
  background-color: var(--primary-color-9);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 4px 16px;
  font-family: var(--font-family);
  font-size: var(--font-size-h3);
  color: var(--color-black);
  font-weight: 500;
}

.CoursesListContainer .CourseCard .courseCardTitleContainer {
  padding: 18px 18px;
  border-bottom: 1px solid;
  border-color: #d5dbdf;
  display: flex;
  justify-content: space-between;
}

.CoursesListContainer .CourseCard .courseCardTitleContainer span {
  font-size: 23px;
  color: var(--primary-color-2);
}

.CoursesListContainer .CourseCard .CourseCardTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h3);
  white-space: nowrap;
  font-weight: 700;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.CoursesListContainer .CourseCard .courseCardImage {
  background: var(--gradient-background);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoursesListContainer .CourseCard .courseCardImage::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.CoursesListContainer .CourseCard .courseCardActions {
  border: 0;
  background-color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  height: 40px;
  width: 40px;
  z-index: 2;
}

.CoursesListContainer .CourseCard .courseCardActions span {
  font-size: 24px;
}

.CoursesListContainer .CourseCard .CourseActionsMenu {
  display: flex;
  border: 1px solid;
  border-color: #e5e7ea;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 220px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
  font-family: var(--font-family);
  font-size: 12px;
  position: absolute;
  z-index: 2;
  top: 55px;
  right: 10px;
}

.CoursesListContainer .CourseCard .CourseActionsMenu .DeleteDisabled {
  color: var(--neutral-color-4) !important;
}

.CoursesListContainer .CourseCard .CourseActionsMenu li {
  margin: 4px 0;
}

.CoursesListContainer .CourseCard .CourseActionsMenu li:first-child {
  border-bottom: 1px solid;
  border-color: #e5e7ea;
  padding-bottom: 5px;
}

.CoursesListContainer .CourseCard .CourseActionsMenu li:last-child {
  border-top: 1px solid;
  border-color: #e5e7ea;
  padding-top: 5px;
}
.CoursesListContainer .CourseCard .CourseActionsMenu li:last-child button {
  color: #cd3636;
}

.CoursesListContainer .CourseCard .CourseActionsMenu button {
  border: 0;
  background-color: transparent;
  height: 34px;
  width: 100%;
  text-align: left;
}

.CoursesListContainer .CourseCard .courseCardInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10%;
  padding: 10px;
}

.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoLeftBox,
.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoRightBox {
  display: flex;
  flex-direction: column;
}

.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoLeftBox div p,
.CoursesListContainer
  .CourseCard
  .courseCardInfo
  .courseCardInfoRightBox
  div
  p {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: 600;
  color: #255e46;
  margin: 0;
}

.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoLeftBox div,
.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoRightBox div {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoLeftBox span,
.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoRightBox span {
  font-size: 14px;
  font-family: var(--font-family);
  color: #383f45;
}

.CoursesListContainer .CourseCard .courseCardInfo .courseCardInfoLeftBox img {
  width: 24px;
}

.CoursesListContainer .CourseCard .courseCardInfo .spacer span {
  height: 22px;
  width: 1px;
  display: block;
  background: #d5dbdf;
}

.CoursesListContainer .CourseNameInput,
.CoursesListContainer .CourseDescriptionInput {
  margin: 20px;
}

.CoursesListContainer .TextInputTitle,
.CoursesListContainer .TextAreaHeading {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 500;
  margin-bottom: 4px;
}

.CoursesListContainer .TextInputStandart,
.CoursesListContainer .TextAreaInput {
  border-width: 1px;
  border-style: solid;
  border-color: #e5e7ea;
  background: #ffffff;
  border-radius: 8px;
}

.CoursesListContainer .TextAreaInput {
  min-height: 100px;
}

.CoursesListContainer .CoursesListFiltersContainer {
  padding: 2rem 0;
  display: flex;
  gap: 20px;
}

.CoursesListContainer .SortPillContainer {
  max-width: 40px;
}

.CoursesListContainer .SortPillContainer .Options {
  z-index: 4;
  left: 0;
}
