
.BoxImagePickerInput .ImagePreviewContainer {
    width: 100%;
    min-height: 120px;
    max-height: 220px;
    background-color: var(--neutral-color-10);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.BoxImagePickerInput .ImagePreviewContainer img {
    max-width: 100%;
    max-height: 220px;
    object-fit: contain;
}

.BoxImagePickerInput .ImagePreviewContainer .ClearButton {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: none;
    border: none;
    outline: none;
    opacity: 0;
    width: 100%;
}

.BoxImagePickerInput .ImagePreviewContainer .ClearButton:hover {
    opacity: 1;
}

.BoxImagePickerInput .BrowseFileButton {
    background-color: var(--primary-color-3);
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    font-family: var(--font-family);
    font-size: .9rem;
    font-weight: normal;
    color: var(--color-white);
    margin-top: 5px;
    cursor: pointer;
}

.BoxImagePickerInput h3 {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    color: #555555;
}
