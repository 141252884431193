.explore-header {
  font-family: var(--font-family);
  margin: 2rem 0;
}

.explore-header_title {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: var(--font-size-h1);
}

.ExploreCoursesEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.ExploreCoursesEmpty p {
  font-weight: bold;
  color: #adadad;
  font-size: 0.8rem;
}

.ExploreCoursesContent{
  font-family: var(--font-family);
}


.explore-header .TitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.explore-header .TitleContainer .FilterContainer {
  margin-top: .5rem;
  margin-bottom: .5rem;
  position: relative;
  width: 100%;
}

.FilterWizardContainer{
  background: var(--neutral-color-10);
  margin-top:.5rem;
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  box-shadow: var(--shadow-level-2);
}

.FilterWizardContainer .BoxHeader{
  padding: 1rem;
  background: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  align-items: center;
}
.FilterWizardContainer .BoxHeader h3{
  padding: 0 1rem;
  margin: 0;
  font-family: var(--font-family);
  color: var(--neutral-color-1);
  font-size: 1rem;
  flex-direction: flex-end;
}

.FilterWizardContainer .BoxHeader span{
  color:var(--color-white);
  padding:.8rem;
  cursor:pointer;
}

.FilterWizardContainer .BoxSubHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .5rem;
  border-bottom: 1px solid #E5E5E5;
}

.FilterWizardContainer .BoxSubHeader h3{
  font-family: var(--font-family);
  font-size: 14px;
  color: #8F8F8F;
  margin-bottom: 0px;
}

.FilterWizardContainer .BoxSubHeader img {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.FilterWizardContainer .BoxContent{
  display: flex;
  flex-direction: column;
  padding: .5rem;   
  font-family: var(--font-family);
  font-size: 1rem;
  color: var(--neutral-color-1);
  max-height: 30vh;
  overflow-y: scroll;
  background-color: var(--color-white);
}

.FilterWizardContainer .BoxContent .BoxContentRow{
  display: flex;
  flex-direction: row;
}

.FilterWizardContainer .BoxContent .BoxContentRow p{
font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.FilterWizardContainer .BoxFooter{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: .5rem;
  border-top: 1px solid #E5E5E5;
  background: var(--neutral-color-10);
}

.FilterWizardContainer .BoxFooter .Clear {
  margin-right: 10px;
}

.FilterBoxContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content:flex-start;
  gap: 10px;   
  flex-wrap: wrap;
  margin-top: 20px;
}

.FilterBoxContainer .FilterNames{
  background: #D9FFE8;  
  border-radius: 50px;
  align-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 24px;
  padding-right: 24px;
  font: inherit;
  outline: inherit;
  font-family: var(--font-family);
  font-size: 0.7rem;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-wrap: nowrap;
  justify-content: space-between;
}

.FilterBoxContainer .FilterNames h3{
  align-self: normal;
  font-family: var(--font-family);
  font-size: 0.8rem;
  margin: 0;
  margin-right: 5px;
}

.FilterBoxContainer .FilterNames img{
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  align-items: baseline;
}



  /* Big screens (1025px and above) */
  @media only screen and (min-width: 1025px) {

    .FilterWizardContainer{
      background: var(--neutral-color-10);
      z-index: 1;
      left: auto;
      right:0;
      width: 100%;
      min-width:320px;
      margin-top:1rem;
    }
    .explore-header .TitleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
    }

    .explore-header_title {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .explore-header .TitleContainer .FilterContainer {
      margin-top: 2rem;
      margin-bottom: 2rem;
      position: relative;
      width: auto;
    }
  }
  

  .ActiveFilters{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:flex-start;
    gap: 10px;   
    padding-bottom: 1%;
}

.ActiveFilters .FilterNames{
    background: #D9FFE8;  
    border-radius: 50px;
    align-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 24px;
    padding-right: 24px;
    font: inherit;
    outline: inherit;
    font-family: var(--font-family);
    font-size: 0.7rem;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-wrap: nowrap;
    justify-content: space-between;
}

.ActiveFilters .FilterNames h3{
    align-self: normal;
    font-family: var(--font-family);
    font-size: 0.8rem;
    margin: 0;
    margin-right: 5px;
}

.ActiveFilters .FilterNames img{
    width: 0.8rem;
    height: 0.8rem;
    cursor: pointer;
    align-items: baseline;
}