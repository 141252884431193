.ChoiceFreeformOverlayCanvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.188);
    border-radius: 20px;
    /* z-index: 5; */
}


.FreeformOverlayQuestionHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 75%, rgba(0,212,255,0) 100%);
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.FreeformOverlayQuestionHeader .ChoiceInfo {
    width: 100%;
}

.CollapseFreeformHeaderButton {
    background: var(--secondary-color-1);
    outline: none;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    transform: rotate(180deg);
    margin-top: 5px;
    margin-left: 20px;
}

.CollapseFreeformHeaderButton span {
    color: var(--primary-color-1);
}

.ExpandFreeformHeaderButton {
    background: var(--secondary-color-1);
    outline: none;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    position: absolute;
    top: 20px;
    right: 15px;
    
}

.ExpandFreeformHeaderButton span {
    color: var(--primary-color-1);
}