.MyLibraryModal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 110;
}

.MyLibraryModalContainer {
    background-color: var(--neutral-color-11);
    padding: 20px 50px;
    border-radius: 12px;
}

.MyLibraryModalContainer .Header {
    position: relative;
    margin-bottom: 18px;
}

.MyLibraryModalContainer h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    font-weight: 600;
    color: var(--primary-color-1);
    text-align: center;
}

.MyLibraryModalContainer .CloseButton {
    position: absolute;
    top: 0px;
    right: 0px;
    background: var(--color-white);
    padding: 5px;
    border: none;
    outline: none;
    display: flex;
}

.MyLibraryModalContainer .CloseButton span {
    font-size: 24px;
}

.MyLibraryModalContainer .CloseButton:hover {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
}


.MyLibraryModalContainer .PlaceholderLogo {
    object-fit: contain;
    background-color: var(--primary-color-2);
    padding-left: 3px;
    padding-right: 3px;
}

.MyLibraryModalContainer .SearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 28px;
}

.MyLibraryModalContainer .FiltersContainer {
    margin-top: 16px;
    margin-bottom: 10px;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MyLibraryModalContainer .FiltersContainer .FilterPill {
    margin-right: 12px;
}

.MyLibraryModalContainer .FilesContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 55vh;
    padding-top: 14px;
}

.MyLibraryModalContainer .ButtonHollow {
    padding: 12px 32px;
}



.MediaLibraryCard {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-white);
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.MediaLibraryCard:hover {
    transform: scale(1.1);
}

.MediaLibraryCard img {
    object-fit: cover;
    max-width: 100%;
}

.MediaLibraryCard .Poster {
    aspect-ratio: 1.77;
    width: 100%;
    background-color: var(--primary-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.MediaLibraryCard .InfoContainer {
    padding: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MediaLibraryCard .InfoContainer p {
    font-family: var(--font-family);
    overflow-wrap: break-word;
    font-size: var(--font-size-5);
    font-weight: 400;
    color: var(--color-black);
}

.MediaLibraryCard .InfoContainer span {
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: var(--neutral-color-6);
}

.MediaLibraryCard .TypeIndicator {
    margin-right: 4px;
}

.MediaLibraryCard .SelectedFileOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    align-items: center;
    justify-content: center;
}

.MediaLibraryCard .SelectedFileOverlay .CheckContainer {
    width: 30px;
    height: 30px;
    background-color: var(--success-color-5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}

.MediaLibraryCard .SelectedFileOverlay span {
    color: var(--color-white);
    font-size: 14px;
}