.AddMChoiceWizardContainer {
  display: flex;
  flex-direction: row;
  max-height: 80vh;
  overflow-y: auto;
}

.AddMChoiceWizardContainer .Content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 10px;
}

.AddMChoiceWizardContainer .Header {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: bold;
  color: var(--neutral-color-1);
  margin-bottom: 0px;
  margin-left: 10px;
}

.AddMChoiceWizardContainer .Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.AddMChoiceWizardContainer .Row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddMChoiceWizardContainer .HeaderContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: space-between;
  margin-right: 26px;
}

.AddMChoiceWizardContainer .AnswersInput {
  margin-bottom: 20px;
  width: 100%;
  margin-right: 10px;
}

.AddMChoiceWizardContainer .Section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddMChoiceWizardContainer .Section .DeleteItem {
  border: none;
  background: none;
  outline: none;
  margin-left: 10px;
  margin-top:20px;
}

.AddMChoiceWizardContainer .OpenAssistant {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--color-white);
  background-color: var(--primary-color-3);
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 60px;
  align-self: flex-end;
}

.AddMChoiceWizardContainer .OpenAssistant:disabled {
  background-color: var(--neutral-color-7);
  cursor: not-allowed;
}

.AddMChoiceWizardContainer .AssistandContainer {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  flex: 0.4;
  border-left: 1px solid #E3E3E3;
  margin-left: 15px;
  height: 100%;
}

.AddMChoiceWizardContainer .AssistandHeader {
  display: flex;
}

.AddMChoiceWizardContainer .AssistandHeader p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: bold;
  color: var(--neutral-color-1);
  margin-bottom: 0px;
  margin-left: 10px;
}

.AddMChoiceWizardContainer .AssistandContainer .Content {
  margin-top: 20px;
}

.AddMChoiceWizardContainer .AssistandContainer .Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.AddMChoiceWizardContainer .AssistandContainer .Footer .Cancel {
  margin-right: 10px;
}

.AddMChoiceWizardContainer .AddAnswerButton {
  align-self: flex-start;
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer {
  margin-bottom: 10px;
}


.AddMChoiceWizardContainer .GeneratedQuestionsContainer p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: #979797;
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer .Title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  color: var(--neutral-color-1);
  cursor: pointer;
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer .QuestionSelect {
  width: 14px;
  height: 14px;
  border: 1px solid var(--neutral-color-1);
  border-radius: 18px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  vertical-align: middle !important;
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer .QuestionSelect ul{
  padding-left: none;
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer .QuestionSelected {
  width: 8px;
  height: 8px;
  background-color: var(--secondary-color-1);
  border-radius: 18px;
  display: inline-block;
}


.AddMChoiceWizardContainer .GeneratedQuestionContainer li {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
}

.AddMChoiceWizardContainer .GeneratedQuestionContainer img {
  vertical-align: sub;
}