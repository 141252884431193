.DropdownContainer {
  position: relative;
  width: 100%;
  max-height: 52px;
  max-height: 252px;
  -webkit-transition:  max-height 0.5s ease-in-out;
  -moz-transition:  max-height 0.5s ease-in-out;
  -ms-transition:  max-height 0.5s ease-in-out;
  -o-transition:  max-height 0.5s ease-in-out;
  transition:  max-height 0.5s ease-in-out;
}

.DropdownDisabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.DropdownBody {
  padding: 13px 8px 13px 20px;
  border-radius: 12px;
  border: 1px solid var(--neutral-color-9);
  background: var(--color-white);
  cursor: pointer;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.DropdownBody .icon-chevron-down {
  font-size: 24px;
  color: var(--neutral-color-7);
  display: block;
  border-left: 1px solid var(--neutral-color-9);
  padding-left: 8px;
}

.DropdownBody .icon-chevron-up {
  font-size: 24px;
  color: var(--neutral-color-7);
  display: block;
  border-left: 1px solid var(--neutral-color-9);
  padding-left: 8px;
}

.DropdownBodyBox {
  padding: 8px;
  cursor: pointer;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--neutral-color-10);
  border-radius: 3px;
  margin-top: 5px;
}


.DropdownContainer .Placeholder {
  color: var(--neutral-color-1);
  opacity: 50%;
}

.DropdownContainer .FieldError {
  border-bottom: 1px solid var(--error-color-1);
}

.DropdownList {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  max-height: 180px;
  overflow-y: auto;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: normal;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 10;
  border: 1px solid var(--neutral-color-9);
  border-radius: 12px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--color-white);
}

.DropdownContainer .ListItem {
  padding: 10px;
  cursor: pointer;
}

.DropdownContainer .ListItem:hover {
  background-color: #E6E6E6;
}

.DropdownTitle {
  font-family: var(--font-family);
  font-size: 12px;
  color: #555555;
  font-weight: bold;
  margin-bottom: 0;
}


.DropdownSubtitleText{
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: normal;
  color: var(--neutral-color-5);      
}


/*new css*/
