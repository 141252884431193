.AnalyticsContainer {
    padding: 30px 22px;
}

.AnalyticsContainer .HeadingSection {
    display: flex;
    flex-direction: row;
    gap: 66px;
    margin-left: 36px;
    margin-right: 36px;
}

.AnalyticsContainer .GraphSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AnalyticsContainer .AnalyticsCardsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 24px;
}

.AnalyticsContainer .TableLegendAndSearch {
    display: flex;
    flex-direction: row;
    margin-top: 42px;
    justify-content: space-between;
}

.AnalyticsContainer .TableLegend {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AnalyticsContainer .TableLegend span {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
    line-height: 20px;
}

.AnalyticsContainer .TableLegend .TableLegendItem {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.AnalyticsContainer .TableLegend .Completed {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #2F7657;
    background: #EFFDF6;
    margin-right: 4px;
}

.AnalyticsContainer .TableLegend .Failed {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #A32E2E;
    background: #FEF2F2;
    margin-right: 4px;
}

.AnalyticsContainer .TableLegend .Progress {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #3B82F6;
    background: #E5F1FF;
    margin-right: 4px;
}

.AnalyticsContainer .TableLegend .Unassigned {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--neutral-color-8);
    background: var(--color-white);
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AnalyticsContainer .TableLegend .UnassignedDash {
    width: 10px;
    height: 1px;
    background-color: var(--neutral-color-7);
}

.AnalyticsContainer .SearchExport {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.AnalyticsContainer .ExportButton {
    border-radius: 12px;
    padding: 10px 14px;
}

/* Grades Table*/

.AnalyticsTableContainer {
    max-height: 80vh;
    overflow: auto;
    padding-bottom: 20px;
    margin-top: 26px;
    border: 1px solid var(--neutral-color-8);
    border-radius: 16px;
}

.AnalyticsTable {
    position: relative;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    background: var(--color-white);
}

.AnalyticsTable .GradebookHeader {
    position: sticky;
    top: 0;
    background-color: var(--neutral-color-10);
    z-index: 1;
}

.AnalyticsTable .SortHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--neutral-color-10);
    border-right: 1px solid var(--neutral-color-9);
    font-weight: 600;
    padding: 27px 24px;
    min-width: 200px;
    word-break: keep-all;
}

.AnalyticsTable .OverallHeader {
    padding-left: 20px;
    font-weight: 500;
    position: sticky;
    left: 0;
    background-color: var(--color-white);
}

.AnalyticsTable .CourseAvgRow td {
    border-bottom: 1px solid #6AD09D;
}

.AnalyticsTable .SelectedRowCol {
    background-color: var(--neutral-color-8) !important;
}

.AnalyticsTable th {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    background-color: var(--neutral-color-10);
    color: var(--color-black);
    font-weight: 400;
    padding: 15px 8px;
    cursor: pointer;
    min-width: 150px;
    border-right: 1px solid var(--neutral-color-9);
}

.AnalyticsTable td {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    color: var(--color-black);
    font-weight: 400;
    padding: 12px 8px;
    min-width: 150px;
    border-right: 1px solid var(--neutral-color-9);
}

.AnalyticsTable .LearnerName {
    position: sticky;
    left: 0;
    font-weight: 400;
    width: 5vw;
    cursor: pointer;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: var(--color-white);
}

.AnalyticsTable .LearnerNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AnalyticsTable .LearnerName img {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 8px;
}

.AnalyticsTable .LearnerName .AvatarPlaceholder {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color-1);
    color: var(--color-white);
}

.AnalyticsTable .LearnerName span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    display: block;
    font-size: var(--font-size-4);
}

.AnalyticsTable .LessonHeader p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.AnalyticsTable .GradeCell {
    text-align: center;
}

.AnalyticsTable .NoResultsDash {
    width: 12px;
    height: 1px;
    background-color: var(--neutral-color-7);
    margin: auto;
}

.AnalyticsTable .GradeSuccessBox {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-weight: 600;
    border: 1px solid var(--success-color-3);
    background: var(--success-color-10);
}

.AnalyticsTable .GradeProgressBox {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-weight: 600;
    border: 1px solid #3B82F6;
    background: #E5F1FF;
}

.AnalyticsTable .GradeFailedBox {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-weight: 600;
    border: 1px solid var(--error-color-3);
    background: var(--error-color-10);
}

.AnalyticsTable .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #666E75;
    display: inline;
}

.AnalyticsTable .arrow-down {
    width: 0; 
    height: 0; 
    display: inline;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #666E75;
}

.AnalyticsTable .SortArrowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.AnalyticsTable .AvgScoreColumn {
    max-width: 60px !important;
    min-width: 60px !important;
    font-weight: 500;
    text-align: center;
}

.AnalyticsTable .AvgSuccess {
    color: var(--success-color-3);
}

.AnalyticsTable .AvgFailed {
    color: var(--error-color-3);
}