.UpgradeHintContainer {
    background-color: #F3F3F3;
    border-radius: 50px;
    display: inline-block;
    padding: 10px;
    margin-top: 10px;
}

.UpgradeHintContainer p {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--primary-color-1);
    font-weight: normal;
    margin-bottom: 0;
}

.UpgradeHintContainer a {
    color: var(--primary-color-3);
    text-decoration: none;
}