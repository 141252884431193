.InvitationNotificationsMenuPopUpContainer {
    background-color: var(--color-white);
    display: flex;
    margin: 10px;
    justify-content: space-between;
    min-width: 220px;
    height: auto;
    width: auto;
    font-family: var(--font-family);
    color:var(--neutral-color-1);
    padding-left: 15px;
    border-radius: 5px;
    padding: 20px;
    background: rgba(244, 244, 244, 0.50);
}

.InvitationNotificationsMenuPopUpContainerNotSeen {
    background: rgba(7, 132, 130, 0.10);
}

.InvitationNotificationsMenuPopUpContainer .InvitationNotificationsMenuPopUpList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1D77770D;
    
}

.InvitationNotificationsMenuPopUpContainer ul,
.InvitationNotificationsMenuPopUpContainer p {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.InvitationNotificationsMenuPopUpContainer ul li:first-child {
    list-style-type: none;
    border-top: 1px solid #F0F0F0;
}

.InvitationNotificationsMenuPopUpContainer ul li {
    list-style-type: none;
    padding: 5px 15px;
    border-bottom: 1px solid #F0F0F0;
}

.InvitationNotificationsMenuPopUpContainer ul li:last-child {
    border-bottom: 0;
}

.InvitationNotificationsMenuPopUpContainer p {
    font-size: 0.9rem;
    width: auto;
    line-height: normal;
    padding: 5px 25px;
    height: auto;
    color:var(--neutral-color-1);
    font-weight: 400;
    line-height: normal;
}
.InvitationNotificationsMenuPopUpContainer p.HeadingText {
    font-size: 1.2rem;
    width: auto;
    padding: 5px 25px;
    height: auto;
    color:#000;
    font-weight: 500;
    line-height: normal;
}


.InvitationNotificationsMenuPopUpContainer img {
    color:var(--neutral-color-1);
    border:1.5px;
    height: auto;
}

.InvitationNotificationsMenuPopUpContainer p.SubtitleText {
    font-size: 0.9rem;
    padding:5px 25px;
    color: #858585;
    font-weight: 400;
    line-height: normal;
    width: auto;
}

.InvitationNotificationsMenuPopUpContainer .ImageTextContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: rgba(29, 119, 119, 0.05);
}

.InvitationNotificationsMenuPopUpContainer .TextContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

}
.InvitationNotificationsMenuPopUpContainer .ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;
}

.InvitationNotificationsMenuPopUpContainer button {
    font-family: var(--font-family);
    font-weight:500;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--neutral-color-1);
    display: inline-flex;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 0.5px solid #CBD1D8;
    background: var(--color-white);
    margin:auto 5px;
    min-width: 5px;
}

.InvitationNotificationsMenuPopUpContainer button.ProfilePicture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    border: 0.5px solid #CBD1D8;
    
}

.InvitationNotificationsMenuPopUpContainer button.AcceptButton {
    padding:3px 8px;
    background-color: var(--primary-color-3);
    border-radius: 2px;
    border: 0.5px solid #CBD1D8;
    min-width: 5px;
    color: var(--color-white);
}

.InvitationNotificationsMenuPopUpContainer button.ViewAllNotificationsButton {
    border: 0.5px solid #CBD1D8;
    min-width: 5px;
    border-radius: 3px;
    background: #005250;
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin: 5px 10px 5px auto;
    display: block;
}

.InvitationNotificationsMenuPopUpContainer .popupArrowDetail {
    width: 50px;
    position: absolute;
    height: 24px;
    overflow: hidden;
    right: 20px;
    top: -16px;
}

.InvitationNotificationsMenuPopUpContainer .popupArrowDetail:before {
    content: "";
    width: 50px;
    position: absolute;
    height: 50px;
    -webkit-transform: rotate(45deg);
    top: 12px;
    z-index: 10;
    background: white;
    box-shadow: none;
    border: 0;
    border-radius: 5px;
}

.flexDisplay {
    display: flex;
  }

