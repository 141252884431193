.LessonAnalyticsQuestionsContainer {
    padding: 30px 22px;
}

.LessonAnalyticsQuestionsContainer .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.LessonAnalyticsQuestionsContainer .Dropdown {
    width: 60%;
}

.LessonAnalyticsQuestionsContainer .StatsCardsContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 45px;
    margin-top: 24px;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard {
    flex: 1;
    background: var(--neutral-color-10);
    border-radius: 24px;
    padding: 20px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard .StatTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    margin-bottom: 8px;
    font-weight: 500;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard .StatValue {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-3);
    font-weight: 400;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard .StatsFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard .StatFooterValue {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    color: var(--neutral-color-1);
    font-weight: 700;
    margin-left: 16px;
}

.LessonAnalyticsQuestionsContainer .LessonStatCard .StatFooterValueIcon {
    font-size: 20px;
    color: var(--neutral-color-7);
}

.LessonAnalyticsQuestionsContainer .ButtonTabsContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 32px;
}

.LessonAnalyticsQuestionsContainer .ButtonTabsContainer .Button {
    border: 1px solid var(--neutral-color-5) !important;
    color: var(--neutral-color-6);
}

.LessonAnalyticsQuestionsContainer .ButtonTabsContainer .ButtonActive {
    border: 1px solid var(--primary-color-5) !important;
    color: var(--color-black);
}


.LessonAnalyticsQuestionsContainer .ButtonTabsContainer .Button:hover {
    color: var(--color-white);
}

.LessonAnalyticsQuestionsContainer .QuestionsList {
    width: 100%;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsNumber {
    width: 58px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-7);
    font-weight: 700;
    margin-bottom: 0;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsBody {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsBody .Divider {
    height: 100%;
    width: 1px;
    background-color: #E5E7EA;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsBody .HeaderDivider {
    height: 30px;
    width: 1px;
    background-color: #E5E7EA;
}


.LessonAnalyticsQuestionsContainer .QuestionDetailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 14px;
    padding-left: 0;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsBody .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    font-weight: 600;
    margin-bottom: 0;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .LearnersCount {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-1);
    font-weight: 600;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .icon-users {
    font-size: 20px;
    margin-left: 4px;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .ProgressChartContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .ProgressChartContainer .Amount {
    position: absolute;
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    font-weight: 600;
    color: var(--neutral-color-2);
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .ExpandButton {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--neutral-color-10);
    font-size: 26px;
    color: var(--color-black);
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsStats .ExpandedButton {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 26px;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 8px;
    background-color: var(--neutral-color-10);
    margin: 4px 57px 4px 0;
    border-radius: 4px;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsClosed {
    border-bottom: 1px solid var(--neutral-color-9);
}

.LessonAnalyticsQuestionsContainer .CorrectOption {
    background-color: var(--color-white);
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .Option {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-6);
    font-weight: 400;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .IsCorrectIndicator {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-color-7);
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .icon-check {
    font-size: 10px;
    font-weight: 800;
    color: #2F7657;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .Correct {
    border: 1px solid #2F7657;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOptionStats {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .Learners {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-1);
    font-weight: 600;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .icon-users {
    margin-left: 4px;
}

.LessonAnalyticsQuestionsContainer .QuestionDetailsOption .Stat {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 600;
    width: 37px;
    text-align: end;
}