.ImagePickerInput {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: 12px;
  color: #3a3a3a59;
  font-weight: normal;
  border-bottom: 1px solid #efeae7;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ImagePickerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.AttachFileButton {
  border: none;
  cursor: pointer;
  border-radius: 50px;
  background-color: var(--neutral-color-3);
  text-align: center;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--color-white);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.AttachFileButton:hover {
  background-color: var(--primary-color-3);
}

.AttachBoxInput .Title {
  color: var(--neutral-color-1);
}

.ImageBrowseButton input {
  display: none;
}

.ImagePickerContainer .AttachFileImage {
  height: 45px;
  width: 45px;
  cursor: pointer;
}

.ImagePickerContainer .ImageContainer {
  margin-right: 7px;
  position: relative;
  margin-top: 10px;
}

.ImagePickerContainer .ClearButton {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.7);
}

.ImagePickerContainer .ClearButton:hover {
  opacity: 1;
}

.ImagePickerContainer .ClearButton img {
  width: 20px;
  height: 20px;
}

.ImagePickerTitle {
  font-family: var(--font-family);
  font-size: 12px;
  color: #555555;
  font-weight: bold;
  margin-bottom: 10px;
}
