.SearchInputOuterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchInputOuterContainer.SmallSearchBox{
  min-width: 0vw;
  display: block;
  border-radius: 0px;
}

.SearchInputContainer {
  background-color: var(--neutral-color-10);
  border-radius: 50px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--neutral-color-9)
}

.SearchInputContainer:focus-within {
  border: 1px solid var(--secondary-color-1);
}

.SearchInputContainer input {
  border: none;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  color: var(--neutral-color-6);
  font-weight: 500;
  background: none;
  outline: none;
  min-width: 15vw;
}

.SearchInputOuterContainer.SmallSearchBox input{
  min-width: 0vw;
}

.SearchInputContainer input::placeholder {
  color: var(--neutral-color-6);
}

.SearchInputContainer input:focus {
  border: none;
  outline: none;
}

.SearchInputContainer span {
  font-size: 24px;
  color: var(--color-black);
  margin-right: 20px;
}

.SearchInputContainer .ClearIcon {
  font-weight: 100;
  cursor: pointer;
}