.GlobalBackButton {
    background: none;
    outline: none;
    border: none;
    padding: 10px;
    font-size: var(--font-size-3);
    font-family: var(--font-family);
    color: var(--primary-color-3);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GlobalBackButton .icon-arrow-left {
    font-size: 20px;
}