.MarketplaceHeader {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
}

.MarketplaceHeader .Title {
    font-size: 48px;
    font-family: var(--font-family);
    color: #3A3A3A;
    font-weight: bold;
    max-width: 55vw;
}

.MarketplaceHeader .Subtitle {
    white-space: break-spaces;
    font-size: 20px;
    font-family: var(--font-family);
    color: #3A3A3A;
    font-weight: normal;
    max-width: 55vw;
}

.MarketplaceHeader .TextContainer {
    padding: 60px;
}

.MarketplaceItemCol {
    margin-bottom: 25px;
}

.MarketplaceItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 16px;
    border: 3px solid #D4D4D4;
    border-radius: 10px;
    height: 100%;
}

.MarketplaceItem img {
    width: 65px;
    height: 65px;
    margin-bottom: 20px;
}

.MarketplaceItem p {
    margin-bottom: 40px;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: #3A3A3A;
    text-align: center;
}

.MarketplaceCheckOut .Title {
    font-family: var(--font-family);
    font-size: 48px;
    font-weight: bold;
    margin-top: 90px;
    margin-bottom: 20px;
    color: #3A3A3A;
}

.MarketplaceCheckOut .CurrentPlan {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: #3A3A3A;
    margin-top: 10px;
}

.MarketplaceCheckOut .MoreFeatures {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: normal;
    color: #3A3A3A;
    margin-top: 10px;
}

.MarketplaceCheckOut .MoreFeatures a {
    text-decoration: none;
    color: var(--primary-color-4)
}

.MarketplaceCheckOut .SummarySectionTitle {
    font-family: var(--font-family);
    font-size: 20px;
    color: #3A3A3A;
    font-weight: normal;
    margin-bottom: 6px;
}

.MarketplaceCheckOut .CheckOutCard {
    border: 3px solid #D4D4D4;
    border-radius: 10px;
    padding: 45px 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MarketplaceCheckOut .ProductTitle {
    font-family: var(--font-family);
    font-size: 20px;
    color: #3A3A3A;
    font-weight: normal;
    margin-bottom: 10px;
}

.MarketplaceCheckOut .DescrptionText {
    font-family: var(--font-family);
    font-size: 14px;
    color: #3A3A3A;
    font-weight: bold;
    margin-bottom: 10px;
}

.MarketplaceCheckOut .ProductDescrption {
    font-family: var(--font-family);
    font-size: 14px;
    color: #3A3A3A;
    font-weight: normal;
}

.MarketplaceCheckOut .ProductImage {
    margin-right: 30px;
}

.MarketplaceCheckOut .QuantitySelector {
    margin-top: 30px;
}

.MarketplaceCheckOut .QuantitySelector input {
    width: 50px;
    font-family: var(--font-family);
    font-size: 20px;
    color: #3A3A3A;
    font-weight: normal;
}

.MarketplaceCheckOut .QuantitySelector .NumbersInputStandart {
    border: none;
}

.MarketplaceCheckOut .QuantitySelector .ButtonsContainer {
    margin-top: 2px;
}

.MarketplaceCheckOut .Price {
    margin-top: 36px;
    font-family: var(--font-family);
    font-size: 20px;
    color: #3A3A3A;
    font-weight: normal;
    width: 120px;
    text-align: center;
}

.MarketplaceCheckOut .NotAvailableMax {
    color: red;
}

@media only screen and (max-width: 992px) {
    .MarketplaceHeader{
        flex-wrap: wrap;
    }

    .MarketplaceHeader img {
        padding-left: 60px;
    }
}

