.PermissionErrorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
}

.PermissionErrorContainer h2 {
    font-family: var(--font-family);
}

.PermissionErrorContainer a {
    font-family: var(--font-family);
    text-decoration: none;
    color: var(--primary-color-4);
}