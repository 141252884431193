
.OrganizationBadge {
    margin: 0;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
}
  
.OrganizationBadge img {
    width: 25px;
    height: 25px;
    border-radius: 15px;
    border: 1px solid black;
    padding: 1px;
    margin-right: 5px;
}
  
.OrganizationBadge p {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: black;
    margin-bottom: 0;
}