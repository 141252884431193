.GoogleOauthContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.GoogleOauthContainer p {
    font-family: var(--font-family);
    color: var(--neutural-color-1);
    font-size: 1rem;
    font-weight: normal;
    margin-top: 10px;
}

.GoogleOauthContainer a {
    font-family: var(--font-family);
    color: var(--primary-color-4);
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: 10px;
    text-decoration: none;
}