.SortPillContainer {
    position: relative;
}

.SortPillContainer .SortPillOpener {
    padding: 6px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--primary-color-5);
    outline: none;
    background: var(--color-white);
    border-radius: 22px;
    font-family: var(--font-family);
    color: var(--color-black);
    font-weight: 500;
    font-size: var(--font-size-4);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    position: relative;
}

.SortPillContainer .SortPillOpener:hover {
    transform: scale(1.05);
    filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.08));
}

.SortPillContainer .icon-sort-arrows {
    font-size: 24px;
    margin-right: 6px;
}

.SortPillContainer .Options {
    position: absolute;
    top: 46px;
    right: 0;
    background-color: var(--color-white);
    z-index: 1;
    border: 1px solid var(--neutral-color-8);
    border-radius: 17px;
    overflow: hidden;
    padding: 4px 8px;
    width: max-content;
}

.SortPillContainer .Options .Option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    font-weight: 500;
    color: var(--color-black);
    cursor: pointer;
}

.SortPillContainer .Options .Option:hover {
    background-color: var(--primary-color-9);
}

.SortPillContainer .Options .OptionActive {
    background-color: var(--primary-color-9);
}
