.SettingsUserProfileContainer {
  padding: 2rem;
  min-height: 50vh;
}

.SettingsUserProfileContainer h5 {
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  color: black;
  font-weight: bold;
  margin-bottom: 6px;
}

.SettingsUserProfileContainer .Subtitle {
  font-family: var(--font-family);
  font-size: var(--font-size-1);
  color: var(--neutral-color-3);
  font-weight: normal;
  margin-bottom: 30px;
  display: block;
}

.SettingsInput {
  margin-bottom: 20px;
}

.SettingsUserProfileContainer button {
  margin-top: 30px;
}

.SettingsUserProfileContainer img {
  border: var(--neutral-color-1);
}

@media only screen and (min-width: 1025px) {
  .SettingsUserProfileContainer {
    padding: 2rem 13rem 2rem 3rem;
  }
}
