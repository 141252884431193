.FilterPill {
    border-radius: 20px;
    padding: 8px 10px;
    border: 1px solid var(--neutral-color-9);
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--color-white);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.FilterPill:hover {
    transform: scale(1.05);
    filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.08));
}

.FilterAll {
    padding: 8px 25px;
}

.FilterPill span {
    margin-right: 8px;
    color: var(--neutral-color-6);
    font-weight: 600;
}

.FilterPill p {
    margin-bottom: 0;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-6);
    font-weight: 500;
}

.FilterPillActive {
    background-color: var(--color-white);
    border: 1px solid var(--primary-color-5);
}

.FilterPillActive span {
    color: var(--color-black);
}

.FilterPillActive p {
    color: var(--color-black);
}