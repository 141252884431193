.LearnerJourneyContainer {
    width: 60vw;
    height: 70vh;
}


.JourneyCard {
    max-width: 139px;
    max-height: 110px;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px;
    border: 2px solid #d3d2d2;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.JourneyCard p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    text-align: center;
}

.JourneyCard .PlaceholderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #F8F8F8;
    width: 125px;
    height: 70px;
}

.JourneyCard .PlaceholderContainer img {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
}

.JourneyNodeContainer .VisitedCard {
    border: 2px solid #2290CE;
}

.JourneyNodeContainer {
    width: 140px;
    max-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ChallengeCorrect {
    color: var(--success-color-1);
    font-size: var(--font-size-2);
    text-align: center;
}

.ChallengeIncorrect {
    color: var(--error-color-1);
    font-size: var(--font-size-2);
    text-align: center;
}

.LogoPlaceholderContainer {
    background-color: var(--primary-color-2);
    object-fit: contain !important;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 125px;
    height: 70px;
}