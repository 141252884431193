.AuthImage {
  background-size: cover;
  order: 1;
  overflow: hidden;
  display: block;
}

.AuthImageContainer {
  height: 60vh;
  position: relative;
  display: none;
}

.AuthImageContainer.SignUpSuccess {
  height: 20vh;
}

.AuthImageContainer.SignUpSuccess::after {
  content: '';
  width: 110%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.545);
  position: absolute;
  left: -12px;
  top: 0;
  z-index: 0;
}

.AuthOutletContainer {
  position: relative;
  order: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.AuthInfoModal {
  position: absolute;
  bottom: 10%;
  left: 10%;
  right: 10%;
  background-color: #011e1e4d;
  backdrop-filter: blur(30px);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
}

.AuthInfoModalTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  color: var(--color-white);
  font-weight: bold;
}

.AuthInfoModalText {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--color-white);
  font-weight: normal;
}

.WelcomeAuthContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  position: relative;
}

.WelcomeAuthContainer h2 {
  color: var(--color-white);
}

.WelcomeAuthContainer h4 {
  color: var(--color-white);
}

@media only screen and (min-width: 1025px) {
  .AuthInfoModalTitle {
    font-family: var(--font-family);
    font-size: 34px;
    color: var(--color-white);
    font-weight: bold;
  }

  .AuthImageContainer {
    height: 90vh;
    position: relative;
    display: block;
  }
  .AuthImage {
    display: block;
  }

  .WelcomeAuthContainer h2 {
    color: var(--neutral-color-1);
  }

  .WelcomeAuthContainer h4 {
    color: var(--neutral-color-1);
  }

  .AuthImageContainer.SignUpSuccess::after {
    display: none;
  }
  .AuthImageContainer.SignUpSuccess {
    height: 100vh;
  }
  .AuthOutletContainer {
    order: 1;
  }
}
@media only screen and (max-width: 767px) {
  .AuthImage {
    display: none;
  }
}

/* Consulta de medios para tablets (por ejemplo, iPads) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .authPage .container-fluid {
    width: 100%;
  }
  .authPage .container-fluid .AuthOutletContainer,.authPage .container-fluid .AuthImage {
    width: 50%;
    height: 100vh;
    overflow: hidden;
  }
  .authPage .container-fluid .AuthImageContainer {
    display: block;
    height: 70vh;
  }
}
