.VideoPlaybackPlayerContainer {
  position: relative;
}

.VideoPlaybackContainer {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.VideoPlaybackScrollingContianer {
  overflow-y: scroll;
}

.QuestionsListContainer .ResultsButton {
  margin-right: 10px;
}

.PlaybackBackButton {
  background-color: var(--primary-color-3);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  border: none;
  outline: none;
  color: var(--color-white);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PreviewBanner {
  background-color: var(--primary-color-1);
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 8px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 210px;
}

.PreviewBanner span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  color: var(--color-white);
  margin-left: 10px;
  white-space: nowrap;
}

.VideoPlaybabackPlaylist {
  padding-top: 10px;
}

.VideoPlaybabackPlaylist .Title {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: bold;
  color: var(--neutral-color-1);
  margin-bottom: 16px;
}

.VideoPlaybabackPlaylist .ItemContainer {
  background-color: var(--color-white);
  box-shadow: 0px 1.46269px 1.46269px 1.46269px var(--neutral-color-10);
  border-radius: 5px;
  padding: 7px;
  width: 100%;
}

.VideoPlaybabackPlaylist .GreyContainer {
  opacity: 0.5;
}

.VideoPlaybabackPlaylist .ClickableContainer {
  cursor: pointer;
}

.VideoPlaybabackPlaylist .PlayingContainer {
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(45, 141, 141, 0.4);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(45, 141, 141, 0.02);
  }
}

.VideoPlaybabackPlaylist .ItemConnectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.VideoPlaybabackPlaylist .ItemConnectorContainer .ItemConnector {
  width: 2px;
  height: 16px;
  background-color: #98bdbd;
}

.VideoPlaybabackPlaylist .ItemPlaceholderImage {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
}

.VideoPlaybabackPlaylist .ItemTitleText {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.VideoPlaybabackPlaylist .ItemTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.VideoPlaybabackPlaylist .ItemPlaceholderContainer {
  position: relative;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  background-color: #f6f6f6;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoPlaybackDetails {
  margin-top: 25px;
  margin-left: 10px;
}

.VideoPlaybackDetails .InfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.VideoPlaybackDetails .InfoContainer .Avatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.VideoPlaybackDetails .InfoContainer .Title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 20px;
}

.VideoPlaybackDetails .InfoContainer .StatsText {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: #979797;
  margin-bottom: 0;
}

.VideoPlaybackDetails .VideoDescriptionContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.VideoPlaybackDetails .VideoDescriptionContainer h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 5px;
}

.VideoPlaybackDetails .VideoDescriptionContainer h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 200;
  color: var(--neutral-color-1);
  margin-bottom: 5px;
}

.PlayerBranchOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    backdrop-filter: blur(10px);
}

.PlayerBranchOverlay::-webkit-scrollbar {
  display: none;
}

.ResultsModalContainer {
  background-color: var(--color-white);
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
}

.ResultsCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  outline: none;
  border: none;
}

.CommentsContainer .CommentBox {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #ecebeb;
  border-radius: 5px;
  margin-bottom: 10px;
}

.CommentsContainer .CommentBox .CommentHeader {
  display: flex;
  flex-direction: row;
}

.CommentsContainer .CommentBox .CommentHeader .NameAvatar {
  width: 20px;
  height: 20px;
  border: 1px solid #005250;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CommentsContainer .CommentBox .CommentHeader .NameAvatar span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-1);
}

.CommentsContainer .CommentBox .CommentHeader .Name {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-left: 5px;
}

.CommentsContainer .CommentContent span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 200;
  color: var(--neutral-color-1);
  margin-top: 5px;
}

.CommentsContainer p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: normal;
  color: var(--neutral-color-1);
}

.player_container{
  position: relative;
}

.player_container .ModalContainer{
  border-radius: 25px;
  width: 97%;
  margin: 23px auto;
  z-index: 3;
}

.player_container .ModalContainer .ModalContent{
  width: 70%;
  min-width: auto;
}

.player_container .ModalContainer .ModalContent .DataTableText{
  white-space: normal;
  max-width: 100%;
}

@media (min-width: 300px) and (max-width: 500px) {
  .player_container .ModalContainer .ModalContent{
    max-height: 90%;
  }

  .VideoPlaybabackPlaylist .ItemPlaceholderContainer {
    position: relative;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    background-color: #f6f6f6;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 200px;
  }


.VideoPlaybabackPlaylist .ItemPlaceholderImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

}