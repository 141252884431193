
.SubscriptionPlan {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1rem;
    background-color: #E7ECEF;
    color: black;
    padding: 20px;
    border-radius: 22px;
    font-family: var(--font-family);
    min-width: 150px;
}

.SubscriptionPlan .Title {
    color: black;
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.SubscriptionPlan .CurrentPlan {
    font-size: 16px;
    color: black;
    font-family: var(--font-family);
    text-align: center;
}

.SubscriptionPlan .Price {
    color: black;
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
}

.SubscriptionPlan .AmountOfUsers {
    border: 1px solid #3A3A3A;
    padding: 5px 15px;
    color: #3A3A3A;
    text-align: center;
    align-self: center;
    border-radius: 50px;
    font-size: 15px;
    font-weight: normal;
}

.SubscriptionPlan .UsersPerMonth {
    font-size: 14px;
    font-weight: 400;
    color: black;
    text-align: center;
    margin-bottom: 9px;
}

.SubscriptionPlan .UsersPerMonthPlaceholder {
    height: 16.5px;
    margin-bottom: 9px;
}

.SubscriptionPlan .Features {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 13px;
    margin-bottom: 18px;
    padding-left: 0;
}

.SubscriptionPlan .Features span {
    color: black;
    font-size: 15px;
    display: block;
    margin-right: 5px;
    margin-top: 4px;
}

.SubscriptionPlan .Features li::marker {
    display: none;
    content: '';
}

.SubscriptionPlan .Features li {
    display: flex;
    font-size: 15px;
    color: black;
    font-family: var(--font-family);
    padding-top: 5px;
    padding-bottom: 5px;
}

.SubscriptionPlan .SelectSubButton {
    width: 100%;
    margin-top: 15px;
    background-color: #011E1E;
    color: var(--accent-color);
    font-size: 15px;
    font-weight: normal;
}

.SubscriptionPlan .SelectSubButton:hover {
    background-color: var(--accent-color);
    color: #011E1E;
}

.SubscriptionPlan .SelectSubTrialButton {
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    color: #011E1E;
    font-size: 15px;
    font-weight: normal;
    border: 1px solid #011E1E;
}

.SubscriptionPlan .SelectSubTrialButton:hover {
    background-color: var(--accent-color);
}

.SubscriptionPlan .SelectSubButtonSecondry {
    width: 100%;
    margin-top: 15px;
    background-color: transparent;
    color: #056260;
    border: 1px solid #056260;
    font-size: 14px;
    font-weight: normal;
}

.SubscriptionPlan .SelectSubButtonSecondry:hover {
    color: white;
    background-color: #056260;
}

.SubscriptionPlan .StartsAt {
    color: black;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
}

.SubscriptionPlan .StartsAtPlaceholder {
    height: 18px;
    margin-top: 10px;
}

.SubscriptionPlan .MoreDetails {
    color: black;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
}

.SubscriptionPlan .PseudoLink {
    text-decoration: underline;
    cursor: pointer;
    color: black;
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 0;
}

.SubscriptionPlan .PseudoLink:hover {
    opacity: 0.7;
}