.AuthHeaderTextLogo {
  max-width: none;
  width: 200px;
}

.AuthHeaderTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h4);
  color: var(--primary-color-1);
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 20px;
}

.AuthHeaderSubtitle {
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--primary-color-1);
  opacity: 0.35;
  margin-bottom: 0;
}

@media only screen and (min-width: 1025px) {
  .AuthHeaderTextLogo {
    max-width: 174px;
  }

  .AuthHeaderTitle {
    font-size: var(--font-size-h2);
  }
}
