.PageTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PageTitleContainer h1 {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: var(--font-size-title-h1);
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.PageTitleContainer span {
    font-size: var(--font-size-h1);
    color: var(--neutral-color-1);
    margin-right: 6px;
}

.PageTitleContainer img {
    width: 32px;
    height: 32px;
}

.PageTitleSubtitle {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--font-size-3);
    color: var(--neutral-color-5);
    margin-bottom: 0;
}

.PageTitleSubtitleWithoutIcon {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--font-size-3);
    color: var(--neutral-color-5);
    margin-bottom: 0;
}