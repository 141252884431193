.ConfirmationModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    background-color: #000000AA;
    height: 100vh;
    
}

.ConfirmationModalContainer {
    background-color: var(--color-white);
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 86px;
    padding-right: 86px;
    border-radius: 5px;
    max-width: 50vw;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.ConfirmationModalContainer h2 {
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--neutral-color-1);
}

.ConfirmationModalContainer .ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.ConfirmationModalContainer .ButtonsContainer .AcceptButton {
    margin-left: 15px;
}

@media (min-width: 300px) and (max-width: 500px) {
    .ConfirmationModalContainer {
        max-width: 90%;
        padding: 2rem 1rem;
    }
  }