
.TooltipCloud {
    background-color: #F6F7F9;
    padding: 10px 12px;
    border-radius: 4px;
    margin-top: 18px;
    font-family: var(--font-family);
    color: var(--primary-color-2);
    font-size: var(--font-size-5);
    outline: 1px solid var(--primary-color-5);
    max-width: 25vw;
    position: relative;
}

.TooltipCloud span {
    white-space: initial;
    text-overflow: ellipsis;
    max-width: 100%;
    word-wrap: break-word;
}

.TooltipCloud .ArrowPosition {
    position: absolute;
    left: 47%;
    top: -12px;
}
