.LessonAnalyticsBranchesContainer {
    padding: 30px 22px;
}

.LessonAnalyticsBranchesContainer .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.LessonAnalyticsBranchesContainer .Dropdown {
    width: 60%;
}

.LessonAnalyticsBranchesContainer .StatsCardsContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 45px;
    margin-top: 24px;
}

.LessonAnalyticsBranchesContainer .LessonStatCard {
    flex: 1;
    background: var(--neutral-color-10);
    border-radius: 24px;
    padding: 20px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LessonAnalyticsBranchesContainer .LessonStatCard .StatTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    margin-bottom: 8px;
    font-weight: 500;
}

.LessonAnalyticsBranchesContainer .LessonStatCard .StatValue {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-3);
    font-weight: 400;
}

.LessonAnalyticsBranchesContainer .LessonStatCard .StatsFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.LessonAnalyticsBranchesContainer .LessonStatCard .StatFooterValue {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    color: var(--neutral-color-1);
    font-weight: 700;
    margin-left: 16px;
}

.LessonAnalyticsBranchesContainer .LessonStatCard .StatFooterValueIcon {
    font-size: 20px;
    color: var(--neutral-color-7);
}

.LessonAnalyticsBranchesContainer .ButtonTabsContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 32px;
}

.LessonAnalyticsBranchesContainer .ButtonTabsContainer .Button {
    border: 1px solid var(--neutral-color-5) !important;
    color: var(--neutral-color-6);
}

.LessonAnalyticsBranchesContainer .ButtonTabsContainer .ButtonActive {
    border: 1px solid var(--primary-color-5) !important;
    color: var(--color-black);
}


.LessonAnalyticsBranchesContainer .ButtonTabsContainer .Button:hover {
    color: var(--color-white);
}

.LessonAnalyticsBranchesContainer .BranchesList {
    width: 100%;
    margin-top: 34px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.LessonAnalyticsBranchesContainer .BranchDetailsNumber {
    width: 58px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-7);
    font-weight: 700;
    margin-bottom: 0;
}

.LessonAnalyticsBranchesContainer .BranchDetailsBody {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.LessonAnalyticsBranchesContainer .BranchDetailsBody .Divider {
    height: 100%;
    width: 1px;
    background-color: #E5E7EA;
}

.LessonAnalyticsBranchesContainer .BranchDetailsBody .HeaderDivider {
    height: 30px;
    width: 1px;
    background-color: #E5E7EA;
}


.LessonAnalyticsBranchesContainer .BranchDetailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 14px;
    padding-left: 0;
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
}

.LessonAnalyticsBranchesContainer .BranchDetailsBody .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    font-weight: 600;
    margin-bottom: 0;
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .LearnersCount {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-1);
    font-weight: 600;
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .icon-users {
    font-size: 20px;
    margin-left: 4px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .ProgressChartContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .ProgressChartContainer .Amount {
    position: absolute;
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    font-weight: 600;
    color: var(--neutral-color-2);
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .ExpandButton {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--neutral-color-10);
    font-size: 26px;
    color: var(--color-black);
}

.LessonAnalyticsBranchesContainer .BranchDetailsStats .ExpandedButton {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 26px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 8px;
    background-color: var(--neutral-color-10);
    margin: 4px 57px 4px 0;
    border-radius: 4px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsClosed {
    border-bottom: 1px solid var(--neutral-color-9);
}

.LessonAnalyticsBranchesContainer .CorrectOption {
    background-color: var(--color-white);
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .Option {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-6);
    font-weight: 400;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .IsCorrectIndicator {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-color-7);
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .icon-check {
    font-size: 10px;
    font-weight: 800;
    color: #2F7657;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .Correct {
    border: 1px solid #2F7657;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOptionStats {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .Learners {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-1);
    font-weight: 600;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .icon-users {
    margin-left: 4px;
}

.LessonAnalyticsBranchesContainer .BranchDetailsOption .Stat {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 600;
    width: 37px;
    text-align: end;
}