.ModalContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 110;
}

.ModalContainer .ModalContent {
    background-color: var(--color-white);
    border-radius: 16px;
    padding: 28px;
    min-width: 40vw;
    position: relative;
    z-index: 200;
    max-height: 90vh;
    overflow-y: auto;
}

.ModalContainer .CloseButton {
    position: absolute;
    top: 21px;
    right: 21px;
    background: var(--color-white);
    padding: 5px;
    border: none;
    outline: none;
    display: flex;
    z-index: 2;
}

.ModalContainer .CloseButton span {
    font-size: 24px;
}

.ModalContainer .CloseButton:hover {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
}
