.PlayerBranchOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    backdrop-filter: blur(10px);
    z-index: 3;
}

.LessonIsEmptyContainer {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.LessonIsEmptyContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
}

.PlayerBranchOverlay::-webkit-scrollbar {
  display: none;
}

.ResultsModalContainer {
    background-color: var(--color-white);
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
}

.ResultsCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    outline: none;
    border: none;
}

.VideoPlaybackPlayerContainer {
    position: relative;
    max-width: 100%;
    aspect-ratio: 1.77;
    max-height: 100%;
}

.VideoPlaybackPlayerContainer .VideoCover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.SmallPlayButton {
    position: absolute;
    left: 10px;
    background: none;
    outline: none;
    border: none;
    background-color: var(--primary-color-3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    z-index: 2;
}

.SmallPlayButton img {
    width: 10px;
    height: 10px;
}

.ImagePlayerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
}

.ImagePlayerContainer img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 0;
}

.ImagePlayerContainer span {
    background-size: cover;
    filter: blur(100px);
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1.4);
}