/* General */
.GenerateAIAssistantContainer {
  padding: 30px 38px;
}

.GenerateAIAssistantContent {
  margin-top: 45px;
}

.GenerateAIAssistantContent .GenerateAIAssistant_FieldContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/* General */

/* Header */
.GenerateAIAssistantContent .GenerateAIAssistantFormHeader {
  margin-bottom: 1rem;
}
.GenerateAIAssistantContent .GenerateAIAssistantFormHeader h1 {
  color: var(--secondary-color-1);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-h2);
  text-align: center;
}

.GenerateAIAssistantContent .GenerateAIAssistantActionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
/* Header */

/* Body */
.GenerateAIAssistantFormBody {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-color-9);
  padding: 20px;
  border-radius: 34px;
  max-width: 700px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
}

.GenerateAIAssistantFormContent {
  padding: 12px 67px;
}
/* Body */

/* Body Inner Header */
.GenerateAIAssistantFormBody .GenerateAIAssistantFormInnerHeader {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb {
  margin-right: 1.2rem;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb
  .LibraryFilesButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GenerateAIAssistantFormBody
  .LibraryFilesButtonContainer
  .DisabledInput {
  cursor: not-allowed;
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb
  a::after {
  position: absolute;
  top: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: var(--neutral-color-9);
  border-radius: 20px;
  left: 0;
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb
  span {
  position: absolute;
  color: #fff;
  font-size: 30px;
  z-index: 3;
  top: 34%;
  left: 34%;
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb
  img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  z-index: 2;
  position: relative;
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantThumb
  .PlaceholderAvatar {
  width: 75px;
  height: 75px;
  z-index: 2;
  position: relative;
}


.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  .GenerateAIAssistantTitleDesc {
  flex: 1;
}
.GenerateAIAssistantFormBody .GenerateAIAssistantFormInnerHeader h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-h3);
}
.GenerateAIAssistantFormBody .GenerateAIAssistantFormInnerHeader h1 span {
  color: var(--error-color-4);
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  textarea::placeholder {
  color: var(--neutral-color-6);
}
.GenerateAIAssistantFormBody .GenerateAIAssistantFormInnerHeader textarea {
  padding: 10px 9px;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-color-9);
  width: 100%;
  resize: none;
  min-height: 100px;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-5);
  
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistantFormInnerHeader
  textarea:focus {
  outline: 1px solid var(--secondary-color-1);
}
/* Body Inner Header */

.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Label
  p {
  margin: 0;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-3);
  margin-right: 0.5rem;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Label
  span.icon-typeQuestion,
.GenerateAIAssistantFormBody
  .SpecialBrowse_FieldContainer
  .SpecialBrowse_Label
  span.icon-typeQuestion {
  background-color: #d2d6db;
  border-radius: 5rem;
  font-size: 20px;
  color: #fff;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Label
  span:not(.icon-typeQuestion) {
  color: var(--error-color-3);
}
/* Fields - Label */
/* Fields - ToggleSwitch */
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Input[data-form-type='switchToggle'] {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: var(--neutral-color-10);
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--neutral-color-9);
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Input[data-form-type='switchToggle']
  div {
  position: relative;
  padding: 1rem;
  width: 50%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-3);
  z-index: 1;
  height: 56px;
  max-height: 56px;
}
.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Input[data-form-type='switchToggle']:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 56px;
  background-color: #fff;
  border-radius: 12px;
  border: 0;
  border-color: var(--neutral-color-9);
  border-style: solid;
  /* border-width: 1px; */
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translateX(0%);
}

.GenerateAIAssistantFormBody
  .GenerateAIAssistant_FieldContainer
  .GenerateAIAssistant_Input[data-form-type='switchToggle'].selected-indirect::after {
  transform: translateX(100%);
}

/*Fields - TitleInput */
.GenerateAIAssistantTitleDesc .TitleInput {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: 400;
  border: none;
  outline: none;
  min-width: 300px;
}

.GenerateAIAssistantTitleDesc .TitleStar {
  color: var(--error-color-4);
  font-size: var(--font-size-3);
  font-weight: 500;
  line-height: 10px;
  display: block;
  margin-top: 6px;
}

.GenerateAIAssistantTitleDesc .AiAssitantTitleInput {
  padding: 17px 9px;
  border: 1px solid var(--neutral-color-9);
  border-radius: 12px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
}

/*Fields - TitleInput */

/* Fields - Special Upload */
.SpecialBrowse_FieldContainer {
  margin-bottom: 1rem;
}

.SpecialBrowse_FieldContainer .SpecialBrowse_Label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.SpecialBrowse_FieldContainer .SpecialBrowse_Label a {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-h5);
  color: var(--secondary-color-1);
  text-decoration: underline;
}
.SpecialBrowse_FieldContainer .SpecialBrowse_Label h5 span {
  color: var(--error-color-4);
  font-family: var(--font-family);
  font-weight: 500;
}
/* Fields - Special Upload */

/* Fields - Search Select */
.SearchDropdown_Container .SearchDropdownBody {
  padding: 13px 8px 13px 20px;
  border-radius: 12px;
  border: 1px solid var(--neutral-color-9);
  background: var(--color-white);
  cursor: pointer;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
/* Fields - Search Select */
.SearchDropdown_Container
  .SearchDropdownBody
  span.SearchDropdownBodyPlaceholder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.SearchDropdown_Container .SearchDropdownBody span.numberSelected {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: 500;
  border-radius: 24px;
  display: block;
  margin-right: 1rem;
  background-color: var(--primary-color-3);
  padding: 0.1rem 0.5rem;
  color: white;
}

.SearchDropdown_Container .SearchDropdownBody .icon-chevron-up,
.SearchDropdown_Container .SearchDropdownBody .icon-chevron-down {
  font-size: 24px;
  color: var(--neutral-color-7);
  display: block;
  border-left: 1px solid var(--neutral-color-9);
  padding-left: 8px;
}

.SearchDropdown_Container .SearchDropdownList {
  margin-top: 10px;
}

.SearchDropdown_Container ul.SearchDropdownListContent {
  list-style: none;
  margin: 0;
  margin-top: 10px;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: normal;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  background-color: var(--color-white);
  padding: 10px 0px;
  border-top: 0;
  border-bottom: 0;
  margin: 0;
  overflow: visible;
}

.SearchDropdown_Container ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.SearchDropdown_Container .Group:last-child {
  padding-bottom: 2rem;
}

.SearchDropdown_Container ul li label {
  cursor: pointer;
}

.SearchDropdown_Container ul li .ImageContainer {
  width: 45px;
  min-width: 45px;
  height: 29px;
  border-radius: 4px;
  margin: 0 8px;
  overflow: hidden;
  background: var(--Gradient-vertically, linear-gradient(180deg, #141E30 0%, #243B55 100%));
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchDropdown_Container ul li .ImageContainer span {
  font-family: var(--font-family);
  font-size: var(--font-size-4);
  color: var(--color-white);
  font-weight: 500;
  text-align: center;
}

.SearchDropdown_Container ul li img {
  width: 100%;
}

.SearchDropdown_Container .SearchDropdownListContent {
  max-height: 220px;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: scroll;
}

.SearchDropdown_Container .SearchDropdownListContent .group {
  margin: 1rem 0;
}

.SearchDropdown_Container .SectionLabel {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: var(--font-size-1);
  color: var(--neutral-color-1);
  margin: 15px 0;
}

.SearchDropdown_Container .SearchDropdownListContent label {
  color: var(--neutral-color-7);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-4);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SearchDropdown_Container .SearchDropdownListFooter {
  display: flex;
  border-width: 1px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-color: var(--neutral-color-9);
  border-style: solid;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: space-around;
  position: relative;
  align-items: center;
}

.SearchDropdown_Container .SearchDropdownListFooter button {
  width: 90px;
}

.SearchDropdownListFooter:after {
  content: '';
  height: 100%;
  width: 1px;
  background: var(--neutral-color-9);
  position: absolute;
}

.SearchDropdown_Container .SearchDropdownListHeader {
  margin-bottom: 40px;
}

.SearchDropdown_Container .SearchDropdownListHeader .SearchInputContainer{
  flex: 1;
}

.SearchDropdown_Container input[type='checkbox'] {
  appearance: none; /* Eliminamos el diseño predeterminado */
  -webkit-appearance: none; /* Compatibilidad con WebKit */
  width: 20px;
  height: 20px;
  flex-shrink: 0; /* Evita que se reduzca */
  background-color: transparent; /* Fondo inicial */
  border: 1px solid var(--neutral-color-7); /* Borde */
  border-radius: 4px; /* Bordes redondeados */
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.SearchDropdown_Container input[type='checkbox']::before {
  background-color: #2f9461; /* Fondo al estar marcado */
  border-color: #2f9461; /* Borde al estar marcado */
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchDropdown_Container input[type='checkbox']:checked::before {
  content: ''; /* Obligatorio para mostrar el pseudo-elemento */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4wNDA1IDAuNzkyODkzQzEzLjQzMSAxLjE4MzQyIDEzLjQzMSAxLjgxNjU4IDEzLjA0MDUgMi4yMDcxMUw1LjcwNzE5IDkuNTQwNDRDNS4zMTY2NiA5LjkzMDk2IDQuNjgzNSA5LjkzMDk2IDQuMjkyOTcgOS41NDA0NEwwLjk1OTY0MSA2LjIwNzExQzAuNTY5MTE3IDUuODE2NTggMC41NjkxMTcgNS4xODM0MiAwLjk1OTY0MSA0Ljc5Mjg5QzEuMzUwMTcgNC40MDIzNyAxLjk4MzMzIDQuNDAyMzcgMi4zNzM4NSA0Ljc5Mjg5TDUuMDAwMDggNy40MTkxMkwxMS42MjYzIDAuNzkyODkzQzEyLjAxNjggMC40MDIzNjkgMTIuNjUgMC40MDIzNjkgMTMuMDQwNSAwLjc5Mjg5M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-position: center;
  color: white; /* Color del check */
  font-size: 12px; /* Tamaño del check */
  font-weight: bold;
  border-radius: 3px;
  flex-shrink: 0; /* Evita que se reduzca */
  height: 20px;
  width: 20px;
  left: -1px;
  top: -1px;
  position: absolute;
}

.SearchDropdownListContainer {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 300px;
  border: 1px;
  border-style: solid;
  border-color: var(--neutral-color-9);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 0;
  padding: 22px 44px;
}

.SelectedOptionsContainer {
  border: 0;
  margin: 0;
  /* border-left: 1px;
  border-right: 1px;
  border-style: solid;
  border-color: var(--neutral-color-9); */
}
.SelectedOptionsContainer ul {
  padding: 1rem;
  margin: 0;
  padding-bottom: 0.5rem;
}
.SelectedOptionsContainer hr {
  margin: 0 1rem;
  padding-bottom: 0.5rem;
  border-color: var(--neutral-color-9);
}
.SelectedOptionsContainer .SelectedOptionsTitle {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: var(--font-size-h2);
  color: var(--neutral-color-1);
  margin-bottom: 0;
  padding: 0.5rem 1rem 0 1rem;
  display: block;
}
/* Fields - Search Select */

/* Fields - browse input */
.SepcialBrowse .LibraryFilesInputContainer {
  margin-top: 1rem;
  border: 0;
  padding: 8px 0px;
}

.SepcialBrowse button {
  color: var(--secondary-color-1);
  font-family: var(--font-family);
  font-weight: 600;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  padding: 0;
}
.SepcialBrowse .LibraryFilesButtonContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.SepcialBrowse .LibraryFilesButtonContainer span {
  color: var(--error-color-4);
}
.SepcialBrowse .LibraryFilesButtonContainer span.icon-typeQuestion {
  background-color: #d2d6db;
  border-radius: 5rem;
  font-size: 20px;
  color: #fff;
}
/* Fields - browse input */
