body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tooltip[id="d-t"] > div.tooltip-inner {
  background-color: white !important;
  color: black !important;
  font-family: var(--font-family);
  color:#3a3a3a;
  font-size: 14px;
  line-height: normal;
  padding: 10px;
}

.tooltip[id="d-t"] > div.tooltip-arrow::before {
  border-bottom-color: white !important;
  color: white !important;
}

.bs-tooltip-bottom[id="d-t"] {
  filter: drop-shadow(0px 1px 5px #D2D2D2);
}

.tooltip[id="g-t"] > div.tooltip-inner {
  background-color: #1D7777 !important;
  color: white !important;
  font-family: var(--font-family);
  color:#1D7777;
  font-size: 14px;
  line-height: normal;
  padding: 10px;
}

.tooltip[id="g-t"] > div.tooltip-arrow::before {
  border-bottom-color: #1D7777 !important;
  color: white !important;
}

.bs-tooltip-bottom[id="g-t"] {
  filter: drop-shadow(0px 1px 5px #D2D2D2);
}

.accordion-button {
  background-color: var(--primary-color-1) !important;
  color: white !important;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  color: white;
}

.accordion-button::after {
  color: white !important;
  stroke: white !important;
  fill: white !important;
  background-image: url('./assets/images/accordion_ic.svg') !important;
}

.MandatoryIndicator {
  padding-left: 3px !important;
  color: red !important;
  padding-left: 3px !important;
  display: inline-block;
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Medium.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Walsheim';
  src: local('GT_Walshheim'), url(./assets/fonts/GT-Walsheim-Pro-Regular.ttf) format('truetype');
  font-weight: 200;
}

:root {

  /* Shadows */
  --shadow-level-1: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-level-2: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  --shadow-level-3: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
  --shadow-level-4: 0px 14px 28px rgba(0, 0, 0, 0.25), 0px 10px 10px rgba(0, 0, 0, 0.22);
  --shadow-level-5: 0px 19px 38px rgba(0, 0, 0, 0.30), 0px 15px 12px rgba(0, 0, 0, 0.22);

  /* Branded colors */
    /* ---Primary */
    --primary-color-1: #141E30;
    --primary-color-2: #24334D;
    --primary-color-3: #384A6A;
    --primary-color-4: #4F6387;
    --primary-color-5: #6A7FA5;
    --primary-color-6: #879CC2;
    --primary-color-7: #A8BCDF;
    --primary-color-8: #CCDDFC;
    --primary-color-9: #DDE9FF;
    --primary-color-10: #EBF2FF;
    --primary-color-11: #FAFCFF;
    /* ---Secondary */
    --secondary-color-1: #2B8982;
    --secondary-color-2: #2B8982;
    --secondary-color-3: #2B8982;
    --secondary-color-4: #2B8982;
    --secondary-color-5: #2B8982;
    --secondary-color-6: #2B8982;
    --secondary-color-7: #2B8982;
    --secondary-color-8: #2B8982;
    --secondary-color-9: #2B8982;
    --secondary-color-10: #2B8982;

  /* Action colors */
    /* ---Success */
    --success-color-1: #1E4D3A;
    --success-color-2: #255E46;
    --success-color-3: #2F7657;
    --success-color-4: #2F9461;
    --success-color-5: #53B483;
    --success-color-6: #6AD09D;
    --success-color-7: #8EE4BA;
    --success-color-8: #B8F1D2;
    --success-color-9: #D9F9E6;
    --success-color-10: #EFFDF6;
    --success-color-11: #F6FEF9;
    /* ---Warning */
    --warning-color-1: #F7B200;
    --warning-color-2: #F7C51A;
    --warning-color-3: #F7D834;
    --warning-color-4: #F7EA4E;
    --warning-color-5: #F7FC68;
    --warning-color-6: #F8FF80;
    --warning-color-7: #FAFF99;
    --warning-color-8: #FCFFAE;
    --warning-color-9: #FEFFC4;
    --warning-color-10: #FFFFDA;
    /* ---Error */
    --error-color-1: #FF0000;
    --error-color-2: #FF1A1A;
    --error-color-3: #FF3434;
    --error-color-4: #FF4E4E;
    --error-color-5: #FF6868;
    --error-color-6: #FF8282;
    --error-color-7: #FF9C9C;
    --error-color-8: #FFB6B6;
    --error-color-9: #FFD0D0;
    --error-color-10: #FFEAEA;

  /* For typography or anything else */
  --neutral-color-1: #1A1D1F;
  --neutral-color-2: #24292E;
  --neutral-color-3: #383F45;
  --neutral-color-4: #454C52;
  --neutral-color-5: #596066;
  --neutral-color-6: #676E76;
  --neutral-color-7: #9EA5AD;
  --neutral-color-8: #CED2D6;
  --neutral-color-9: #E5E7EA;
  --neutral-color-10: #F6F7F9;
  --neutral-color-11: #FAFAFA;

  --color-white: #FFFFFF;
  --color-black: #000000;

  --gradient-background: linear-gradient(180deg, #141E30 0%, #243B55 100%);
  --accent-color: #B8EF36;

  /* Customizable variables for workspace white-labeling */
    --default-primary-color-1: #141E30;
    --default-primary-color-2: #24334D;
    --default-primary-color-3: #384A6A;
    --default-primary-color-4: #4F6387;
    --default-primary-color-5: #6A7FA5;
    --default-primary-color-6: #879CC2;
    --default-primary-color-7: #A8BCDF;
    --default-primary-color-8: #CCDDFC;
    --default-primary-color-9: #DDE9FF;
    --default-primary-color-10: #EBF2FF;
    --default-primary-color-11: #FAFCFF;
    /* ---Secondary */
    --default-secondary-color-1: #2B8982;
    --default-secondary-color-2: #2B8982;
    --default-secondary-color-3: #2B8982;
    --default-secondary-color-4: #2B8982;
    --default-secondary-color-5: #2B8982;
    --default-secondary-color-6: #2B8982;
    --default-secondary-color-7: #2B8982;
    --default-secondary-color-8: #2B8982;
    --default-secondary-color-9: #2B8982;
    --default-secondary-color-10: #2B8982;
    /*Background*/
    --default-gradient-background: linear-gradient(180deg, #141E30 0%, #243B55 100%);

    --default-accent-color: #B8EF36;

  /* Typography */
    /* ---Headings */
    --font-family: Montserrat;
    --font-size-title-h1: 36px;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 18px;
    --font-size-h5: 16px;
    --font-size-h6: 14px;
    /* ---Paragraphs */
    --font-size-paragraph: 16px;
    --line-height-paragraph: 24px;
    /* ---Various texts */
    --font-size-1: 20px;
    --line-height-1: 30px;
  
    --font-size-2: 18px;
    --line-height-2: 27px;
  
    --font-size-3: 16px;
    --line-height-3: 24px;
  
    --font-size-4: 14px;
    --line-height-4: 21px;
  
    --font-size-5: 12px;
    --line-height-5: 18px;
  
    --font-size-6: 10px;
    --line-height-6: 15px;
}

.row [class*='col-'] {
  transition: all 0.2s ease-in-out, max-width .5s ease-in-out;
}