.TapyblAiHomeContainer {
    padding: 30px 38px;
}

.TapyblAiHomeContainer .PageTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.TapyblAiHomeContainer .PageSubtitle {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-5);
    font-weight: 500;
}

.TapyblAiHomeContainer .InfoSection {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TapyblAiHomeContainer .InfoSection .Heading {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    font-weight: 500;
    color: var(--primary-color-1);
}

.TapyblAiHomeContainer .InfoSection .Options {
    display: flex;
    flex-direction: row;
}

.TapyblAiHomeContainer .PathCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--gradient-background);
    padding: 24px 46px;
    border-radius: 20px;
    border: 1px solid var(--neutral-color-10);
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
    margin: 40px 30px;
    text-decoration: none;
    max-width: 25vw;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.TapyblAiHomeContainer .PathCard:hover {
    transform: scale(1.1);
}

.TapyblAiHomeContainer .PathCard span {
    margin-bottom: 26px;
    font-size: 70px;
    display: block;
    color: var(--accent-color);
}

.TapyblAiHomeContainer .PathCard .PathTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    font-weight: 500;
    color: var(--color-white);
}

.TapyblAiHomeContainer .PathCard p {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-white);
    font-weight: 500;
    text-align: center;
    max-width: 100%;
    white-space: wrap;
}