.EditorRoot {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}

.EditorRoot .HeaderActions {
    display: flex;
    flex-direction: row;
}

.EditorRoot .PreviewButton {
    margin-right: 10px;
}

.EditorRoot .PublishedIndicatorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.EditorRoot .PublishedIndicatorContainer img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.EditorRoot .PublishedIndicatorContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
}

.EditorRoot .OptionsInput {
    margin-bottom: 15px;
}

.EditorPathwayColumn {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}

.NextColumns {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.EditorNodeContainer {
    width: 140px;
    max-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.EditorNodeContainer .MultipleChoiceOption {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EditorChallengeNodeContainer {
    width: 150px;
    max-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.EditorCard {
    max-width: 139px;
    max-height: 110px;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px;
    border: 2px solid #d3d2d2;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.EditorCard:hover {
    border-color: #9CD8804D;
    box-shadow: 0px 3px 4px 1px #c4c4c4;
}

.EditorCard p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
    text-align: center;
}

.EditorCard .PlaceholderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #F8F8F8;
    width: 125px;
    height: 70px;
}

.EditorCard .PlaceholderNoImage {
    background-color: #011E1E;
}

.EditorCard .PlaceholderContainer img {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
}

.ReplaceWithContainer {
   margin-top: 20px;
   margin-bottom: 10px;
}

.ReplaceWithContainer p {
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    color: #555555;
    font-weight: bold;
    margin-bottom: 0;
}

.ReplaceWithContainer img:hover {
    opacity: 0.6;
}

.ReplaceWithContainer img {
    width: 22px;
    height: 22px;
    display: block;
}

.ReplaceActionsContainer {
    position: relative;
}

.EdgeTooltipContainer {
    margin-top: 5px;
}

.ForceWidthCard {
    width: 139px;
}

.SelectedEditorCard {
    border: 2px solid #9CD880 !important;
}

.EditorPathwayColumn .ArrowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EditorPathwayBridge {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.react-flow__attribution {
    visibility: hidden;
}

.EditorContent {
    overflow: hidden;
    background-color: var(--color-white);
    border-radius: 5px;
    max-height: 100%;
}

.EditorOptionsSectionTitle {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 10px;
}

.ChoiceHeader {
    padding: 3px 10px;
    border: 1px dashed #98BDBD;
    border-radius: 5px;
    display: flex;
}

.ChoiceHeaderSelected {
    padding: 3px 10px;
    border-radius: 5px;
    border: 2px solid #9CD880;
}

.ChoiceHeader span {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: normal;
    line-height: 24px;
    margin-left: 5px;
    white-space: nowrap;
}

.ChoiceQuestionNode {
    margin-bottom: 0 !important;
}

.MChoiceCard {
    width: 199px !important;
    max-width: 199px !important;
    z-index: 2;
    position: relative;
}

.MChoiceQuestion {
    display: flex;
    flex-direction: row;
    width: 199px;
    align-items: center;
    justify-content: center;
}

.MChoiceQuestion p {
    margin-left: 5px;
    margin-bottom: 0;
    margin-right: 10px;
}

.MChoiceAnswersContainer {
    width: 199px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
}

.MChoiceAnswersContent {
    width: 170px;
    background-color: var(--color-white);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    padding: 15px;
}

.MChoiceAnswersContent span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MChoiceAnswersContent .MChoiceAnswer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}


.ChallengeCorrect {
    color: var(--success-color-1);
    font-size: var(--font-size-1);
    text-align: center;
}

.ChallengeIncorrect {
    color: var(--error-color-1);
    font-size: var(--font-size-1);
    text-align: center;
}

.ChallengeBranchAIHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.ChallengeBranchAIHeader .Question {
    white-space: nowrap;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-family);
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.ChallengeBranchAIHeader img {
    width: 18px;
    height: 18px;
}

.ChallengeBranchAICard {
    width: 170px;
    max-height: 200px;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px;
    border: 2px solid #d3d2d2;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ChallengeBranchAICard:hover {
    border-color: #9CD8804D;
    box-shadow: 0px 3px 4px 1px #c4c4c4;
}

.ChallengeBranchAIHeader .ChallengeIcon {
    font-size: 20px;
    color: var(--primary-color-5);
}

.ChallengeBranchAICard p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
    text-align: center;
}

.ChallengeBranchAICard .MaskPreview {
    position: relative;
}

.ChallengeBranchAICard .MaskPreview img {
    max-width: 100%;
}

.ChallengeBranchAICard .MaskPreview canvas {
    position: absolute;
    left: 0;
    opacity: 0.4;
    max-width: 100%;
}

.DuplicateBackgorundError {
    font-size: 10px;
    color: var(--error-color-1);
    text-align: center;
}

.ChoiceNodeReplace .ReplaceButton {
    top: 25px;
}

.ImageMaxDuration {
    font-size: var(--font-size-5);
    font-family: var(--font-family);
    color: #9E9E9E;
}

.OptionsButton {
    width: 100%;
    margin-top: 5px;
}