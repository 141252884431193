.WorkspaceContainer {
    padding: 30px 38px;
}
/* No Subscription State*/
.SubscriptionStartContainer {
    background-color: var(--neutral-color-10);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 30px;
}

.SubscriptionStartContainer img {
    width: 15%;
}

.SubscriptionStartContainer h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-h1);
    font-weight: bold;
    margin-top: 15px;
}

.SubscriptionStartContainer p {
    font-family: var(--font-family);
    font-size: var(--font-size-h4);
    font-weight: normal;
    margin-bottom: 3px;
}

.SubscriptionStartContainer .StartTrialButton {
    margin-top: 15px;
}

.SubscriptionStartContainer .ClickToUpgrade {
    font-family: var(--font-family);
    font-size: var(--font-size-paragraph);
    font-weight: 200;
    margin-top: 20px;
}

.SubscriptionStartContainer span {
    font-family: var(--font-family);
    font-size: var(--font-size-h6);
    font-weight: normal;
    margin-top: 15px;
}
/* No Subscription State*/

.WorkspaceContainer .SectionHeader {
    font-family: var(--font-family);
    font-size: var(--font-size-1);
    color: var(--color-black);
    margin-bottom: 20px;
    font-weight: 400;
    margin-top: 36px;
}

.WorkspaceContainer .Section {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 14px;
}

.WorkspaceContainer .DashboardCard {
    border-radius: 24px;
    border: 1px solid var(--neutral-color-8);
    padding: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.WorkspaceContainer .DashboardCard .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    margin: 8px 12px;
    font-weight: 500;
}

.WorkspaceContainer .DashboardCard .CardCenteredBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.WorkspaceContainer .DashboardCard .AvailableBalance {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-3);
    font-weight: 400;
    text-align: center;
}

.WorkspaceContainer .DashboardCard .AiCredits {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    color: var(--color-black);
    font-weight: 700;
}

.WorkspaceContainer .DashboardCard .StorageAvailable {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    font-weight: 700;
    line-height: 32px;
    margin: 2px 12px;
}

.WorkspaceContainer .LinksAndAssistants {
    flex: 0.7;
    display: flex;
    flex-direction: column;
}

.WorkspaceContainer .QuickLinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.WorkspaceContainer .QuickLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: var(--neutral-color-10);
    border: 1px solid var(--neutral-color-10);
    text-decoration: none;
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: var(--neutral-color-4);
    padding: 12px 5px;
    width: 76px;
    height: 76px;
}

.WorkspaceContainer .QuickLink:hover {
    border: 1px solid var(--secondary-color-1);
}

.WorkspaceContainer .QuickLink .Icon {
    width: 24px;
    height: 24px;
    min-height: 24px;
    font-size: 24px;
    margin-bottom: 4px;
}

.WorkspaceContainer .QuickLink span {
    display: block;
}

.WorkspaceContainer .AssistantsCard {
    margin-top: 21px;
    flex: 1;
    position: relative;
}

.WorkspaceContainer .AssistantsCard .icon-ai_assistant_ic {
    font-size: 130px;
    color: var(--neutral-color-9);
    position: absolute;
    bottom: 4px;
    left: -30px;
}

.WorkspaceContainer .UsersChart {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WorkspaceContainer .UsersChart .TotalUsers {
    position: absolute;
}

.WorkspaceContainer .UsersChart span {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 400;
    color: var(--neutral-color-3);
    line-height: 16px;
    text-align: center;
    display: block;
    margin-top: 14px;
}

.WorkspaceContainer .UsersChart p {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    font-weight: 700;
    color: var(--color-black);
    line-height: 32px;
    text-align: center;
    margin-top: 6px;
}

.WorkspaceContainer .UsersLegend {
    margin: 0px 12px;
    border-top: 1px solid var(--neutral-color-9);
}

.WorkspaceContainer .UsersLegend .LegendCircle {
    width: 12px;
    height: 12px;
    border-radius: 12px;
}

.WorkspaceContainer .UsersLegend table {
    width: 100%;
    font-family: var(--font-family);
}

.WorkspaceContainer .UsersLegend .LegendTitle {
    font-size: var(--font-size-4);
    color: var(--neutral-color-3);
    font-weight: 400;
    line-height: 20px;
    padding: 6px 0px;
}

.WorkspaceContainer .UsersLegend .CountValue {
    text-align: center !important;
}

.WorkspaceContainer .UsersLegend .LegendValue {
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 500;
    line-height: 20px;
    text-align: end;
}

.WorkspaceContainer .CreatorsAndTime {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 14px;
}

.WorkspaceContainer .LearnersChart {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    pointer-events: none;
}

.WorkspaceContainer .CoursesScore {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 14px;
}

.WorkspaceContainer .CoursesCardBody {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 37px;
    margin-bottom: 37px;
}

.WorkspaceContainer .CoursesCardBody .Divider {
    height: 100%;
    width: 1px;
    background-color: var(--neutral-color-8);
}

.WorkspaceContainer .CoursesCardBody .Label {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
}

.WorkspaceContainer .CoursesCardBody .AiCredits {
    margin-bottom: 12px;
}

.WorkspaceContainer .CourseStatusGraphContainer {
    display: flex;
    flex-direction: row;
    width: 330px;
    height: 210px;
    align-items: flex-end;
    position: relative;
}

.WorkspaceContainer .CourseStatusVerticalLine {
    height: 100%;
    width: 1px;
    background-color: var(--neutral-color-8);
}

.WorkspaceContainer .CourseStatusProgressBlock {
    width: 110px;
    min-height: 20px;
}

.WorkspaceContainer .CoursePercentage {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.WorkspaceContainer .CoursePercentageIndicator {
    width: 110px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    text-align: center;
    font-weight: 500;
}

.WorkspaceContainer .CourseAmount {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 3px;
    width: 100%;
}

.WorkspaceContainer .CourseAmountIndicator {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    text-align: start;
    font-weight: 400;
    width: 110px;
    padding-left: 27px;
}

.WorkspaceContainer .CourseLabels {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: -30px;
    width: 100%;
}

.WorkspaceContainer .CourseLabels .CourseAmountIndicator {
    padding: 0px !important;
    text-align: center;
}

.WorkspaceContainer .CourseIndicator {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    position: absolute;
    background-color: red;
}

.WorkspaceContainer .CourseProgressCard {
    flex: 2;
    margin-left: 12px;
}