.PasswordInputValidationContainer {
  margin-top: 6px;
}

.PasswordInputDetailsTitle {
  font-family: var(--font-family);
  font-size: 12px;
  color: #9e9e9e;
  font-weight: bold;
  margin-bottom: 0;
  padding-left: 10px;
  margin-top: 10px;
}

.PasswordInputValidationSectionContainer {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.PasswordInputValidationRadioOuter {
  border: 1px solid #9e9e9e;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.PasswordInputValidationRadioInner {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #22cd29;
}

.PasswordInputValidationText {
  font-family: var(--font-family);
  font-size: 10px;
  color: #9e9e9e;
  font-weight: bold;
  margin-bottom: 0;
}

.PasswordInputContainerAuth {
    align-items: center;
    background-color: #e7ecef;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    width: 100%;
}

.PasswordInputContainerStandart {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: var(--font-size-2);
  color: var(--neutral-color-1);
  font-weight: normal;
  border-bottom: 1px solid #efeae7;
  padding: 15px 20px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.PasswordInputTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-2);
  color: #555555;
  font-weight: bold;
  margin-bottom: 0;
}

.PasswordInputContainerStandart input::placeholder {
  color: var(--neutral-color-1);
  opacity: 50%;
}

.PasswordInputInput {
    appearance: none;
    background-color: initial;
    border: none;
    color: #011e1e;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    outline: none;
    width: 100%;
}

.PasswordInputEye {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: var(--neutral-color-1);
}

.TextBoxStyle {
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--neutral-color-1);
  font-weight: normal;
  padding-bottom: 15px;
  border-bottom: 1px solid #efeae7;
}

.TextBoxStyle input {
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--neutral-color-1);
  font-weight: normal;
  width: 100%;
}

.PasswordInputError {
  border-bottom: 1px solid var(--error-color-1);
}