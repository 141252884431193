
.AnalyticsCard {
    background-color: var(--neutral-color-10);
    flex: 1;
    border-radius: 16px;
    border: 1px solid var(--neutral-color-8);
    padding-top: 25px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 130px;
}

.AnalyticsCard p {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    color: var(--neutral-color-3);
    font-weight: 700;
    margin-bottom: 2px;
}

.AnalyticsCard span {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
    text-align: center;
}

.AnalyticsCard .AnalyticsCardSmallText {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--secondary-color-1);
    font-weight: 700;
}
