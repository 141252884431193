
.ChallengeInfoHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}

.ChallengeInfoHeader .InfoLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.ChallengeInfoHeader .InfoRight {
  flex: 1 1 auto;
  justify-content: flex-end;
  display: flex;
}

.ChallengeInfoHeader .Question {
  flex: 2 1 100%;
  justify-content: center;
  display: flex;
}

.ChallengeInfoHeader .Question p {
  font-family: var(--font-family);
    font-size: calc(100vh / 40);
    font-weight: normal;
    margin-bottom: 0;
    color: var(--color-white);
}

.ChallengeInfoHeader .Percent {
  font-family: var(--font-family) !important;
  font-size: 13px !important;
  font-weight: bold !important;
  color: var(--primary-color-3) !important;
}

.ChallengeInfoHeader .InfoBlock {
  padding: 4px;
  border-radius: 5px;
  background-color: var(--color-white);
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ChallengeInfoHeader .InfoBlockText {
  padding-left: 10px;
  padding-right: 10px;
}

.ChallengeInfoHeader .InfoBlock span {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: normal;
  color: var(--neutral-color-1);
  white-space: nowrap;
}

.ChallengeInfoHeader .InfoBlock .TypeIndicator {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: normal;
    color: var(--primary-color-1);
    display: block;
    margin-right: 5px;
}


.ChallengeInfoHeader .InfoModal {
    position: absolute;
    right: 20px;
    top: 50px;
  }
  
  .ChallengeInfoHeader .InfoModal .InfoOpener {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .ChallengeInfoHeader .InfoModal .InfoBody {
    margin-top: 10px;
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
  }
  
  .ChallengeInfoHeader .InfoModal .InfoBody img {
    width: 20px;
    height: 20px;
    object-fit: fill;
  }
  
  .ChallengeInfoHeader .InfoModal .InfoBody span {
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-size: 12px;
    font-weight: normal;
  }

  @media only screen and (min-width: 767px) {
    .ChallengeInfoHeader .InfoModal .InfoBody {
      min-width: 250px;
    }
    
  }