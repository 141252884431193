.AddLearnersContainer {
    margin-top: 20px;
}

.AddLearnersContainer .HeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AddLearnersContainer .Input {
    margin-left: 10px;
    margin-top: 10px;
}

.AddLearnersContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-left: 10px;
}

.AddLearnersContainer .Footer .Cancel {
    margin-right: 10px;
}

.FailedEmailsContainer {
    margin-top: 20px;
    margin-left: 10px;
}

.FailedEmailsContainer .FailedEmailError {
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    margin: 0;
}

.FailedEmailsContainer .FailedEmailReason {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--neutral-color-1);
    font-weight: bold;
    margin: 0;
}

.react-multi-email {
    border-radius: 0 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding-bottom: 15px !important;
    border-color: var(--neutral-color-10) !important;
}

.react-multi-email.focused {
    border-color: var(--neutral-color-10) !important;
}