.AcknowledgmentOverlay {
    padding: 20px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AcknowledgmentOverlay p {
    font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-white);
  text-align: center;
}

@media only screen and (max-width: 767px) {
    .AcknowledgmentOverlay p{
        font-size: 0.8rem;
        padding-left:0.3rem;
        padding-right:0.3rem;
    }
  }
  