.MaleFemaleVoiceContainer .FemaleVoice {
    margin-right: 45px;
}

.MaleFemaleVoiceContainer img {
    cursor: pointer;
}

.MaleFemaleVoiceContainer img:hover {
    transform: scale(1.1);
}