.CoursePreviewContainer {
    padding: 20px;
}

.CoursePreviewContainer .Title {
    font-family: var(--font-family);
    font-size: 36px;
    font-weight: normal;
    color: black;
    margin: 0;
    margin-top: 0.5rem;
}
  

.CoursePreviewContainer .Description {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: normal;
    color: black;
    margin-bottom: 0;
    line-height: 1.5;
    margin-top: 1rem;
}

.CoursePreviewContainer .FoldersHeading {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: normal;
    color: black;
    margin-top: 15px;
}

.CoursePreviewContainer .FolderWarning {
    color: #FF0000;
    margin-left: 5px;
}

.CoursePreviewContainer .LessonContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CoursePreviewContainer .LessonContainer .LessonWarning {
    color: #FF0000;
    margin-left: 5px;
}

.CoursePreviewContainer .LessonContainer p {
    margin-bottom: 0;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: black;
    margin-left: 5px;
}

.CoursePreviewContainer .LessonContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.CoursePublishCard {
    padding: 18px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.CoursePublishCard .Poster {
    max-height: 30vh;
    object-fit: cover;
    width: 100%;
}
  
.CoursePublishCard .Title {
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-weight: normal;
    margin-top: 0.5rem;
    color: black;
    margin-bottom: 0;
}
  
.CoursePublishCard .CourseActionInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.CoursePublishCard .CourseActionInfo img {
    margin-bottom: 3px;
}

.CoursePublishCard .Info {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: normal;
}
  
.CoursePublishCard .PublishButton {
    margin-top: 0.5rem;
}

.CoursePublishCard .PublishDate {
    font-family: var(--font-family);
    color: var(--neutral-color-3);
    font-size: var(--font-size-5);
    line-height: var(--line-height-1);
    margin-top: 10px;
    margin-bottom: 0;
}

.CoursePublishCard .ContactPrompt {
    text-align: center;
    margin-top: 5px;
}