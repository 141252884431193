.ToggleSwitch_Container {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: var(--neutral-color-10);
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--neutral-color-9);
    height: 55px;
    max-height: 55px;
  }

  .ToggleSwitch_Container .ToggleDisabled {
    cursor: not-allowed;
  }
  
  .ToggleSwitch_Button {
    flex: 1;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    color: var(--neutral-color-6);
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: 400;
  }
  
  .ToggleSwitch_Button.selected {
    color: var(--neutral-color-1);
    font-weight: 500;
  }
  
  .ToggleSwitch_Indicator {
    position: absolute;
    bottom: 0;
    height: 5px;
    transition:
      left 0.3s ease,
      width 0.3s ease;
    z-index: 0;
    height: 53px;
    background-color: #fff;
    border-radius: 12px;
    border: 0;
    border-color: var(--neutral-color-9);
    border-style: solid;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transform: translateX(0%);
  }