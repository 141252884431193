.GenerateAIVideosContainer {
  padding: 30px 38px;
}

.GenerateAIVideosContainer .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  position: relative;
}

.GenerateAIVideosContainer .Header .BackButton {
  position: absolute;
  left: 0;
}

.GenerateAIVideosContainer .Header h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  color: var(--secondary-color-1);
  font-weight: 500;
}

.GenerateAIVideosContainer .Content {
  margin-top: 40px;
}

/* geneation steps */
.GenerateAIVideosContainer .GenerationStepsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.GenerateAIVideosContainer .GenerationStepActive .StepNumber {
  background-color: var(--secondary-color-1) !important;
}

.GenerateAIVideosContainer .GenerationStepActive span {
  color: var(--secondary-color-1) !important;
}

.GenerateAIVideosContainer .GenerationStepsContainer .GenerationStep {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GenerateAIVideosContainer .GenerationStepsContainer .StepNumber {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 24px;
  background-color: var(--neutral-color-8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.GenerateAIVideosContainer .GenerationStepsContainer .StepNumber span {
  font-family: var(--font-family);
  font-size: var(--font-size-4);
  font-weight: 500;
  color: var(--color-white) !important;
}

.GenerateAIVideosContainer .GenerationStepsContainer .GenerationStep span {
  font-family: var(--font-family);
  font-size: var(--font-size-4);
  font-weight: 500;
  color: var(--neutral-color-8);
  text-align: center;
}

.GenerateAIVideosContainer .GenerationStepsContainer .GenerationStep .icon-chevron-right {
  display: block;
  margin-left: 4px;
  margin-right: 10px;
  font-size: 22px;
}

/*generation body*/

.GenerateAIVideosContainer .Body {
  display: flex;
  flex-direction: row;
  background-color: var(--neutral-color-11);
  border-radius: 34px;
  border: 1px solid var(--neutral-color-9);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  min-height: 55vh;
}

.GenerateAIVideosContainer .Steps {
  flex: 2;
  padding: 20px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.GenerateAIVideosContainer .Footer{
  display: flex;
  flex-direction: row;
  padding-top: 24px;
}

.GenerateAIVideosContainer .Footer .SkipButton {
  margin-right: 6px;
}

.GenerateAIVideosContainer .StepTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  font-weight: 600;
  color: var(--secondary-color-1);
  text-align: center;
}

.GenerateAIVideosContainer .UploadFileInfo {
  font-family: var(--font-family);
  font-size: var(--font-size-4);
  font-weight: 400;
  color: var(--neutral-color-6);
  text-align: center;
}

.GenerateAIVideosContainer .SectionTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-1);
  font-weight: 500;
}

.GenerateAIVideosContainer .SectionBottomMargin {
  margin-bottom: 40px;
}

.GenerateAIVideosContainer .StepContainer {
  padding: 0px 25px;
}

.GenerateAIVideosContainer .DurationCheckboxGroup {
  margin-top: 40px;
  margin-bottom: 40px;
}

.GenerateAIVideosContainer .AiMediaOptions {
  margin-top: 20px;
}

.GenerateAIVideosContainer .FileOrientationError {
  background-color: var(--neutral-color-11);
  padding: 24px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GenerateAIVideosContainer .FileOrientationError p {
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  color: var(--neutral-color-1);
  font-weight: 400;
  margin-bottom: 0;
}

.GenerateAIVideosContainer .FileOrientationError span {
  font-size: var(--font-size-h2);
  color: var(--warning-color-1);
  margin-right: 10px;
}

.GenerateAIVideosContainer .DropdownGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.GenerateAIVideosContainer .DropdownGroup .LanguageDropdown {
  flex: 1;
  display: flex;
}

/* WIZARD */
.GenerateAIVideosContainer .HelpTip {
  padding: 36px 26px;
  flex: 1;
}

.GenerateAIVideosContainer .HelpTip h3 {
  font-weight: 600;
  font-size: var(--font-size-5);
  color: var(--secondary-color-1);
  font-family: var(--font-family);
}

.GenerateAIVideosContainer .HelpTip p {
  font-weight: 400;
  font-size: var(--font-size-5);
  color: black;
  font-family: var(--font-family);
}


.GenerateAIVideosContainer .HelpTip li {
  font-weight: 400;
  font-size: var(--font-size-5);
  color: black;
  font-family: var(--font-family);
}

.GenerateAIVideosContainer .HelpTip span{
  margin-right: 5px;
}

.GenerateAIVideosContainer .HelpTip ul {
  padding-left: 12px;
}

.GenerateAIVideosContainer .SummaryStep {
  font-family: var(--font-family);
}

.GenerateAIVideosContainer .SummaryStep ul {
  padding: 0;
}

.GenerateAIVideosContainer .SummaryStep p,.GenerateAIVideosContainer .SummaryStep ul li{
  font-weight: 400;
  font-size: var(--font-size-4);
  color: var(--neutral-color-6);
  display: block;
}

.GenerateAIVideosContainer .SummaryStep .CreditsCost {
  font-weight: bold;
}

.GenerateAIVideosContainer .SummaryStep .CreditError {
  color: var(--error-color-5);
}

.GenerateAIVideosContainer .SummaryStep h3 {
  color: var(--neutral-color-1);
  font-size: var(--font-size-3);
  font-weight: 500;
  margin-top: 30px;
}
