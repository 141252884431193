.CopyFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.CopyFieldContainer .Heading {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: 200;
    margin-bottom: 0;
    white-space: nowrap;
}

.CopyFieldContainer .DataBox {
    background-color: var(--neutral-color-10);
    padding: 5px 10px;
    border-radius: 3px;
    margin-left: 10px;
    border: 1px solid var(--neutral-color-10);
}

.CopyFieldContainer .DataBox p {
    margin-bottom: 0;
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    color: var(--neutral-color-1);
    font-weight: 200;
    white-space: nowrap;
}

.CopyFieldContainer img {
    margin-left: 10px;
}

.CopyFieldContainer:hover .DataBox {
    border: 1px solid var(--neutral-color-2);
}