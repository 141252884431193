
.CardOptionsInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

.CardOptionsInput .CardDisabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}
  
.CardOptionsInput .CardOption {
    background-color: var(--neutral-color-10);
    display: flex;
    height: 76px;
    aspect-ratio: 1;
    border-radius: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--neutral-color-9);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0;
    position: relative;
}

.CardOptionsInput .CardOption img {
    object-fit: contain;
    width: 100%;
    border-radius: 16px;
}

.CardOptionsInput .CardSelected {
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--secondary-color-1);
    background-color: white;
}

.CardOptionsInput .CardSelected p,.CardOptionsInput .CardSelected span  {
    color: var(--primary-color-1) !important;
}

.CardOptionsInput .CardOption:hover {
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
    background: var(--color-white);
    transform: scale(1.1);
}
  
.CardOptionsInput .CardOption p {
    font-family: var(--font-family);
    font-size: var(--font-size-1);
    font-weight: 500;
    color: var(--neutral-color-7);
    margin-bottom: 4px;
}
  
.CardOptionsInput .CardOption span {
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    font-weight: 500;
    color: var(--neutral-color-7);
    text-align: center;
}

.CardOptionsInput .ImageSelectedIndicator {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--secondary-color-1);
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CardOptionsInput .ImageSelectedIndicator span {
    font-size: 13px;
    color: var(--color-white) !important;
    font-weight: bold;
}