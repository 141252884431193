
.MChoiceOverlay {
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .MChoiceOverlay::-webkit-scrollbar {
    display: none;
  }
  

.MChoiceOverlay p {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: normal;
    color: var(--color-white);
    margin-bottom: 5px;
  }
  
  .MChoiceOverlay .InfoLeft {
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .MChoiceOverlay .Content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
  }
  
  .MChoiceOverlay .Content .Question {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: normal;
    color: var(--color-white);
    margin-bottom: 25px;
  }
  
  .MChoiceOverlay .Content .Answers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .MChoiceOverlay .AnswerBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .MChoiceOverlay .AnswerBox .Checkbox {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 1.5px solid white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .MChoiceOverlay .AnswerBox .Checkbox .Checked {
    width: 12px;
    height: 12px;
    background-color: #22ce29;
    border-radius: 1px;
  }
  
  .MChoiceOverlay .AnswerBox span {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--color-white);
    max-width: 70%;
  }
  
  .MChoiceOverlay .AnswerCircle {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .MChoiceOverlay .AnswerCircle .Checkbox {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 1.5px solid white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .MChoiceOverlay .AnswerCircle .Checkbox .Checked {
    width: 12px;
    height: 12px;
    background-color: #22ce29;
    border-radius: 12px;
  }
  
  .MChoiceOverlay .AnswerCircle span {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    color: var(--color-white);
  }
  
  .MChoiceOverlay .AnswersSubmit {
    margin-top: 30px;
  }
  
  
  .MChoiceOverlay .AnswersSubmit span {
    color:#fff
  }
  
  
@media only screen and (max-width: 767px) {
    .MChoiceOverlay .Content .Question{
        font-size: 1.2rem;
        margin-bottom:0.5rem;
    }
    .MChoiceOverlay .Content .Answers{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .MChoiceOverlay .AnswersSubmit{
        margin-top:0.5rem;
    }
    .MChoiceOverlay .AnswerCircle,
    .MChoiceOverlay .AnswerBox {
        margin-right: 1rem;
    }
    .MChoiceOverlay .InfoModal .InfoBody{
        width: 220px;
    }
  }
  