.RolesListContainer {
    padding-top: 20px;
}

.RolesListContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
}

.RolesListContainer .rdt_TableRow {
    border-bottom: 1px solid var(--neutral-color-10) !important;
}

.RoleCreateContainer {
    padding-top: 20px;
}

.RoleCreateContainer h2 {
    font-family: var(--font-family);
    font-size: 20px;
    color: var(--neutral-color-1);
    margin-bottom: 4px;
    font-weight: normal;
}

.RoleCreateContainer h5 {
    font-family: var(--font-family);
    font-size: 16px;
    color: #A4A9AC;
    font-weight: 200;
}

.RoleCreateContainer .NameInput {
    margin-top: 20px;
    margin-bottom: 30px;
}

.PermissionsContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: bold;
}

.PermissionsContainer .CheckboxColumn {
    margin-bottom: 10px;
}

.RoleCreateContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
}

.RoleCreateContainer .Footer .Cancel {
    margin-right: 10px;
}

.RoleCreateContainer .ErrorBox {
    margin-top: 10px;
}

.RolesListContainer .DataTableTextUsers {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: normal;
    color: var(--neutral-color-1);
    margin-bottom: 0;
    display: inline-block;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    
}

.RolesListContainer [data-column-id="users"].rdt_TableCell, [data-column-id="users"].rdt_TableCol_Sortable {    
    justify-content:center ;
    text-align: center;
    width: 100%;
}
