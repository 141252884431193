.TablePaginationContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #EAEAEA;
}

.TablePaginationContainer .SideButton {
    outline: none;
    text-decoration: none;
    background: none;
	color: var(--neutral-color-7);
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    font-weight: 500;
}

.TablePaginationContainer .NumberButton {
    outline: none;
    text-decoration: none;
    background: none;
	color: var(--neutral-color-7);
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    font-weight: 500;
    color: #A4A4A4;
}

.TablePaginationContainer .Active {
    color: var(--neutral-color-3);
}

.TablePaginationContainer .ArrowNext {
    transform: rotate(180deg);
}

.PaginationCondensed button {
    padding-left: 4px;
    padding-right: 4px;
}