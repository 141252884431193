.UserAvatarContainer {
    border-radius: 100px;
    display: inline-flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff7d;
}

.UserAvatarText {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--color-white);
}

.UserAvatarContainer img {
    border-radius: 50%;
    max-height: 32px;
    max-width: 32px;
    object-fit: cover;
    object-position: center;
    min-height: 30px;
    min-width: 30px;
   
}