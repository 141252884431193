.LTIPlayerContainer {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.LTIPlayerSize {
    /* height: 100vh; */
    max-width: 100vw;
    max-height: 100vh;
    aspect-ratio: 1.77;
}