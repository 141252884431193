.DashboardRoot {
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--color-white);
}

.DashboardRootContent {
  overflow-y: auto;
}

.DashboardRootSideNav {
  height: 100%;
  padding: 17px;
  background-color: white;
  position: relative;
  border-right: 1px solid var(--neutral-color-8);
}

.DashboardRootSideNav .NavItemsList {
  margin-top: 36px;
  position: relative;
  padding: 0px 3px;
}

.DashboardRootSideNav h5 {
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  font-weight: normal;
  color: var(--neutral-color-6);
  margin-bottom: 8px;
}

.DashboardRootSideNav .NavListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border-left: 5px solid var(--color-white);
  color: var(--primary-color-1);
  margin-bottom: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.DashboardRootSideNav .NavListItemContainer:hover {
  background-color: var(--primary-color-10);
  border-left: 5px solid var(--primary-color-10);
  color: var(--color-white);
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 12px 8px 9px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--neutral-color-1);
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  font-weight: 400;
}

.DashboardRootSideNav .NavListItemContainer .MainRouteLink .NavIcon {
  margin-right: 8px;
  font-size: 20px;
}

.DashboardRootSideNav .activeRouteLink {
  background-color: var(--primary-color-10);
  border-left: 5px solid var(--primary-color-1);
}

.DashboardRootSideNav .activeRouteLink:hover {
  background-color: var(--primary-color-10);
  border-left: 5px solid var(--primary-color-1);
}

.DashboardRootSideNav .activeRouteLink .MainRouteLink {
  color: var(--primary-color-1) !important;
  font-weight: 500 !important;
}

.DashboardRootSideNav .NavItemsList .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3px;
  margin-bottom: 32px;
}

.DashboardRootSideNav .NavItemsList .Header img {
  width: 44px;
  height: 44px;
  border-radius: 15px;
  margin-right: 12px;
  object-fit: cover;
}

.DashboardRootSideNav .NavItemsList .Header h2 {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: var(--font-size-h5);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.DashboardRootSideNav .Divider {
  margin-top: 30px;
  border-top: 2px solid var(--neutral-color-10);
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.DashboardRootSideNav .MainRouteLinkCollapsed {
  width: auto !important;
  padding: 8px !important;
  justify-content: center;
}

.DashboardRootSideNav .MainRouteLinkCollapsed .NavIcon {
  margin-right: 0 !important;
}

.DashboardRootSideNav .NavListItemCollapsed {
  justify-content: center !important;
}

.DashboardRootSideNav .HeaderCollapsed {
  justify-content: center;
}

.DashboardRootSideNav .HeaderCollapsed img {
  margin-right: 0 !important;
}

.DashboardRootSideNav .MenuCollapseContainer {
  position: relative;
  height: 43px;
}

.DashboardRootSideNav .MenuCollapse {
  border: none;
  outline: none;
  background: var(--color-white);
  position: absolute;
  top: 0px;
  right: -32px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.DashboardRootSideNav .MenuOpen span {
  font-size: 24px;
  color: var(--secondary-color-1);
}

.DashboardRootSideNav .MenuClosed span {
  transform: rotate(-180deg);
  font-size: 24px;
  color: var(--secondary-color-1);
}

.DashboardRootSideNav .OrgAvatarPlaceholder {
  width: 27px;
  height: 27px;
  min-width: 27px;
  border-radius: 28px;
  margin-right: 5px;
  border: 1px solid var(--primary-color-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardRootSideNav .OrgAvatarPlaceholder p {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-h4);
  color: black;
}