.Switch {
    width: 64px;
    height: 32px;
    padding: 2px;
    background-color: var(--neutral-color-8);
    border-radius: 64px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.Switch .Knob {
    width: 28px;
    height: 28px;
    background-color: var(--color-white);
    border-radius: 28px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.Switch .KnowEnabled {
    transform: translate(32px);
}

.Switch .StateText {
    position: absolute;
    left: 6px;
    display: block;
    font-size: var(--font-size-4);
    font-weight: 600;
    color: var(--color-white);
    pointer-events: none;
}

.Switch .StateEnabled {
    transform: translate(28px);
}

.SwitchEnabled {
    background-color: var(--secondary-color-1);
}