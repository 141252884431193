.AiAssistantChatboxContainer {
    position: fixed;
    right: 30px;
    bottom: 30px;
    border-radius: 24px;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #e9ecef;
    box-shadow:
      0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    display: flex;
    justify-content: center;
    width: 350px;
    flex-direction: column;
    overflow: hidden;
  }

  .AiAssistantChatboxContainer .LoaderContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxBody {
    height: 50vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow-y: scroll;
  }

  .AiAssistantChatboxContainer .AiAssistantChatboxBody.empty {
    align-items: center;
    justify-content: center;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxBody span.empty {
    font-weight: 500;
    margin: 0;
    color: var(--neutral-color-8);
    padding: 1rem 4rem;
    text-align: center;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody.empty
    .Chatbox-conversation {
    padding: 1rem 4rem;
  }

  .AiAssistantChatboxContainer
  .Chatbox-conversation h5 {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
}
  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--gradient-background);
    padding: 14px 20px;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader a:hover {
    transform: scale(0.95);
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader .icon-ai_assistant_ic {
    font-size: 30px;
    color: var(--accent-color);
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader span {
    color: var(--color-white);
    margin-left: 0.5rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--font-size-4);
  }

  
  .AiAssistantChatboxContainer .AiAssistantChatboxHeader .icon-x-close {
    font-size: 20px;
    color: #aef600;
    margin: 0 0 0 auto;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter {
    padding: 0.5rem 1rem;
    border: 0;
    border-top-width: 1px;
    border-style: solid;
    border-color: var(--neutral-color-9);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter button:hover {
    transform: scale(0.95);
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter .chat-input {
    width: 100%;
    padding: 1rem;
    border: 0;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 500;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter .icon-eraser {
    font-size: 25px;
    color: var(--primary-color-2);
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter .chat-input:focus,
  .AiAssistantChatboxContainer
    .AiAssistantChatboxFooter
    .chat-input:focus-visible {
    outline: 0;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxFooter
    .chat-input::placeholder {
    color: var(--neutral-color-8);
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxFooter .icon-send {
    font-size: 25px;
    color: #2b8982;
  }
  
  .AiAssistantChatboxContainer .AiAssistantChatboxBody .Chatbox-conversation {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-ai {
    justify-content: flex-start;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-ai
    span {
   font-size: 26px;
   color: var(--accent-color);
  }

  .AiAssistantChatboxContainer
  .AiAssistantChatboxBody
  .AiAvatarContainer {
    background: var(--gradient-background);
    width: 39px;
    height: 39px;
    max-width: 39px;
    max-height: 39px;
    min-width: 39px;
    min-height: 39px;
    margin-right: 10px;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 3px;
    padding-right: 3px;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-ai
    img {
    max-width: 80%;
    border-radius: 8px;
    margin: 10px 0;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-ai
    p {
    margin: 0;
    background-color: var(--primary-color-10);
    border-top-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 12px 20px;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-user {
    justify-content: flex-end;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-user
    div {
    margin: 0;
    background: linear-gradient(
      to bottom,
      rgba(20, 30, 48, 1) 0%,
      rgba(36, 59, 85, 1) 100%
    );
    border: 1px solid #444446;
    border-radius: 12px 0px 12px 12px;
    padding: 0.5rem;
    max-width: 70%;
  }
  
  .AiAssistantChatboxContainer
    .AiAssistantChatboxBody
    .Chatbox-conversation.Chatbox-user
    p {
    margin: 0;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-white);
    word-wrap: break-word;
  }