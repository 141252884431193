.LoginFormContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
}

.LoginErrorContainer {
  margin-top: 1rem;
}

.LoginInputContainer {
  margin-top: 20px;
}

.LoginForgotPassword {
  text-align: end;
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
}

.LoginSubmitButtonContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.GoogleButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.GoogleButtonContainer span {
  font-family: 'Roboto', arial, sans-serif !important;
}

.LoginDontHaveAccount {
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.LoginSignup {
  font-family: var(--font-family);
  font-size: 14px;
  color: #898989;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
}

.SignupDisabled {
  pointer-events: none;
  opacity: 0.4;
}

@media only screen and (min-width: 1025px) {
  .LoginFormContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 90%;
  }
}
