.UploadVideoButtonContainer button {
    background-color: var(--primary-color-1);
    color: var(--color-white);
    border-radius: 12px;
	border: none;
	padding: 10px 14px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.UploadVideoButtonContainer button:hover {
	background-color: var(--primary-color-3);
    color: var(--color-white);
}

.UploadVideoButtonContainer span {
    vertical-align: middle;
    margin-right: 4px;
    transform: rotate(-90deg);
    font-weight: 600;
}

.UploadVideoButtonContainerDisabled button {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780;
    cursor: not-allowed;
    justify-content: center;
}