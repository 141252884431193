.VideoSourcesContainer {
  padding: 30px 38px;
  overflow-y: hidden;
  max-height: 100%;
}

.VideoSourcesContainer .HeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 46px;
}

.VideoSourcesContainer .SearchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.VideoSourcesContainer .SearchContainer .Search {
  margin-right: 20px;
}

.VideoSourcesContainer .FiltersContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.VideoSourcesContainer .FiltersContainer .FilterPill {
  margin-right: 12px;
}

.VideoSourcesContainer .LibraryList {
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow-y: scroll;
  overflow-y: scroll;
  height: 70vh;
  overflow-x: hidden;
  padding-bottom: 20px;
  max-height: 100%;
}

.VideoSourcesContainer .LibraryList .LibraryCol {
  padding-bottom: calc(var(--bs-gutter-x)* .5);
  padding-top: calc(var(--bs-gutter-x)* .5);
}

.VideoSourcesContainer .LibraryFileCardContainer {
  display: flex;
  height: 100%;
}

.VideoSourcesContainer .LibraryFileCard {
  border: 1px solid var(--neutral-color-9);
  border-radius: 4px;
  background: var(--color-white);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.VideoSourcesContainer .LibraryFileCard .ImageContainer {
  background: var(--gradient-background);
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1.77;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoSourcesContainer .LibraryFileCard .FileOptionsPosition {
  position: absolute;
  top: 9px;
  right: 11px;
}

.VideoSourcesContainer .LibraryFileCard .ImageContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.VideoSourcesContainer .LibraryFileCard .PlaceholderLogo {
  width: 60% !important;
  object-fit: contain !important;
}

.VideoSourcesContainer .LibraryFileCard .ImageContainer span {
  font-size: 44px;
  color: var(--accent-color);
}

.VideoSourcesContainer .LibraryFileCard .InfoContainer {
  padding: 14px 12px 9px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.VideoSourcesContainer .LibraryFileCard .InfoContainer p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--color-black);
  font-weight: 500;
  margin-bottom: 4px;
}

.VideoSourcesContainer .LibraryFileCard .DateUploaded {
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  color: var(--neutral-color-6);
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 26px;
}

.VideoSourcesContainer .LibraryFileCard .Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.VideoSourcesContainer .LibraryFileCard .TypeIndicator {
  margin-right: 6px;
}

.VideoSourcesContainer .LibraryFileCard .Footer span {
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  color: var(--neutral-color-5);
  font-weight: 500;
  line-height: 20px;
}

.VideoSourcesContainer .FileStatusDisplayContaier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.VideoSourcesContainer .FileStatusDisplayContaier .StepCircle {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: var(--neutral-color-8);
  margin-bottom: 11px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.VideoSourcesContainer .FileStatusDisplayContaier .StepFinished {
  background-color: var(--success-color-4);
}

.VideoSourcesContainer .FileStatusDisplayContaier .StepProgress {
  animation: blink-animation 2s linear infinite;
  -moz-animation: blink-animation 2s linear infinite;
  -webkit-animation: blink-animation 2s linear infinite;
}

@keyframes blink-animation {
  0% { background-color: var(--neutral-color-8); }
  50% { background-color: var(--success-color-4); }
  100% { background-color: var(--neutral-color-8); }
}

@-webkit-keyframes blink-animation {
  to {
    background-color: var(--success-color-4);
  }
}

.VideoSourcesContainer .FileStatusDisplayContaier .StatusText {
  color: var(--neutral-color-5);
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.VideoSourcesContainer .FileStatusDisplayContaier .StepsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.VideoSourcesContainer .LibraryFileOptions {
  position: relative;
}

.VideoSourcesContainer .LibraryFileOptions .Opener {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 80px;
  background: var(--color-white);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.VideoSourcesContainer .LibraryFileOptions .LoadingContainer {
  position: absolute;
  top: 3px;
}

.VideoSourcesContainer .LibraryFileOptions .Opener:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.08));
}

.VideoSourcesContainer .LibraryFileOptions .Opener span {
  font-size: 24px;
}

.VideoSourcesContainer .LibraryFileOptions .Options {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: var(--color-white);
  z-index: 1;
  border: 1px solid var(--neutral-color-8);
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  width: max-content;
}

.VideoSourcesContainer .LibraryFileOptions .Option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  font-weight: 500;
  color: var(--color-black);
  cursor: pointer;
}

.VideoSourcesContainer .LibraryFileOptions .DeleteOption {
  color: var(--error-color-4);
  border-top: 1px solid var(--neutral-color-9);
}

.VideoSourcesContainer .LibraryFileOptions .DeleteOptionDisabled {
  color: var(--neutral-color-7);
  border-top: 1px solid var(--neutral-color-9);
}

.VideoSourcesContainer .LibraryFileOptions .Option:hover {
  background-color: var(--primary-color-9);
}

.VideoSourcesContainer .LibraryFileOptions .OptionActive {
  background-color: var(--primary-color-9);
}

.DownloadTranscriptContainer .TranscriptTitle {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--primary-color-1);
  text-align: center;
}

.DownloadTranscriptContainer .TranscriptItem {
  padding: 21px 14px 21px 12px;
  border: 1px solid var(--neutral-color-9);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.DownloadTranscriptContainer .TranscriptItem span {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  font-weight: 500;
  color: var(--primary-color-1);
}

.DownloadTranscriptContainer .icon-download_ic {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  display: block;
}

.DownloadTranscriptContainer .icon-download_ic:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity:.5;
  cursor:pointer;
}













.VideoSourcesContainer .TableDeleteButton {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: none;
}

.VideoSourcesContainer .TableNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.VideoSourcesContainer .TableNameContainer .TableNameContainerThumb {
  width: 50px;
  background: var(--neutral-color-7);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.VideoSourcesContainer .TableNameContainer img {
  max-width: 48px;
  border-radius: 5px;
  max-height: 38px;
  height: 30px;
  object-fit: cover;
}

.VideoSourcesContainer .TableText {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.VideoSourcesContainer .TableTextNotReady {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-6);
  margin-bottom: 0;
}

.VideoSourcesContainer .rdt_TableHead {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: normal;
  color: var(--neutral-color-5);
}

.VideoSourcesContainer .rdt_TableRow {
  border: none;
  border-bottom-width: 0px !important;
}

.VideoSourcesContainer .rdt_TableHeadRow {
  border-bottom-width: 0px !important;
  min-height: 0px !important;
  padding-bottom: 20px !important;
  padding-top: 10px;
}

.VideoSourcesContainer .DeleteDisabled {
  color: var(--neutral-color-5);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
}

.VideoSourcesContainer .Delete {
  color: var(--error-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
}

.VideoSourcesContainer .Transcript {
  color: var(--primary-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.VideoSourcesContainer .Transcript:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}

.VideoSourcesContainer .Preview {
  color: var(--primary-color-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-4);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
}

.VideoSourcesContainer .Preview:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}

.VideoSourcesContainer .Delete:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}

.VideoSourcesContainer .MaxFileSize {
  font-family: var(--font-family);
  font-size: 11px;
  font-weight: normal;
  color: var(--neutral-color-5);
  text-align: center;
  position: absolute;
  bottom: -20px;
  white-space: nowrap;
}

.VideoSourcesContainer .UploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.VideoSourcesContainer [data-column-id='size'].rdt_TableCol_Sortable,
[data-column-id='actions'].rdt_TableCol_Sortable,
[data-column-id='created_date'].rdt_TableCol_Sortable,
[data-column-id='size'].rdt_TableCell,
[data-column-id='created_date'].rdt_TableCell,
[data-column-id='actions'].rdt_TableCell {
  /* text-align: center;
  width: 100%; */
  justify-content: center;
}

.VideoSourcesContainer [data-column-id='size'].rdt_TableCol_Sortable {
  padding-right: 10px;
}

.VideoSourcesContainer [data-column-id='created_date'].rdt_TableCol_Sortable {
  padding-right: 5px;
}

.VideoSourcesContainer .NoPlaceholderImage {
  height: 30px;
  background-color: var(--primary-color-2);
  object-fit: contain !important;
  padding-left: 5px;
  padding-right: 5px;
}

.VideoDownloadButton {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin-left: 10px;
}

.VideoDownloadButton img {
  width: 16px;
  height: 16px;
}

.VideoDownloadButton:hover {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 0.5;
  cursor: pointer;
}