.NotificationsMenuPopUpContainer {
  background-color: var(--color-white);
  border: 0.5px solid #cbd1d8;
  border-radius: 5px;
  position: absolute;
  top: 65px;
  min-width: 220px;
  height: auto;
  width: auto;
  font-family: var(--font-family);
  color: var(--neutral-color-1);
}

.NotificationsMenuPopUpContainer ul{
  max-height: 320px;
  overflow: hidden;
  overflow-y: auto;
}

.NotificationsMenuPopUpContainer ul,
.NotificationsMenuPopUpContainer p {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.NotificationsMenuPopUpContainer ul li:first-child {
  border-top: 1px solid #f0f0f0;
}

.NotificationsMenuPopUpContainer ul li {
  padding: 5px 15px;
  border-bottom: 1px solid #f0f0f0;
}

.NotificationsMenuPopUpContainer ul li:last-child {
  border-bottom: 0;
}

.NotificationsMenuPopUpContainer p {
  font-size: 0.9rem;
  width: auto;
  line-height: normal;
  padding: 5px 25px;
  height: auto;
  color: var(--neutral-color-1);
  font-weight: 400;
  line-height: normal;
}
.NotificationsMenuPopUpContainer p.HeadingText {
  font-size: 1.2rem;
  width: auto;
  padding: 5px 25px;
  height: auto;
  color: var(--neutral-color-1);
  font-weight: 500;
  line-height: normal;
}

.NotificationsMenuPopUpContainer img {
  color: var(--neutral-color-1);
  border: 1.5px;
  height: auto;
}

.NotificationsMenuPopUpContainer p.SubtitleText {
  font-size: 0.9rem;
  padding: 5px 25px;
  color: #858585;
  font-weight: 400;
  line-height: normal;
  width: auto;
}

.NotificationsMenuPopUpContainer .ImageTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(29, 119, 119, 0.05);
}

.NotificationsMenuPopUpContainer .TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.NotificationsMenuPopUpContainer .ButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 25px;
}

.NotificationsMenuPopUpContainer button {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--neutral-color-1);
  display: inline-flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #cbd1d8;
  background: var(--color-white);
  margin: auto 5px;
  border-radius: 5rem;
  min-width: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.NotificationsMenuPopUpContainer button:hover {
  background-color: var(--primary-color-3);
  color: var(--color-white);
}

.NotificationsMenuPopUpContainer button.ProfilePicture {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  border: 0.5px solid #cbd1d8;
}

.NotificationsMenuPopUpContainer button.AcceptButton {
  padding: 3px 8px;
  background-color: var(--primary-color-2);
  border-radius: 2px;
  border: 0.5px solid #cbd1d8;
  min-width: 5px;
}

.NotificationsMenuPopUpContainer button.AcceptButton:hover {
  background-color: var(--primary-color-3);  
}

.NotificationsMenuPopUpContainer button.ViewAllNotificationsButton {
  border: 0.5px solid #cbd1d8;
  min-width: 5px;
  border-radius: 3px;
  background: var(--primary-color-2);
  display: inline-flex;
  padding: 0.3rem 1rem;
  justify-content: center;
  align-items: center;
  margin: 5px 10px 5px auto;
  display: block;
  border-radius: 5rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size:.9rem;
}
.NotificationsMenuPopUpContainer button.ViewAllNotificationsButton:hover {
  background-color: var(--primary-color-3);
  color: var(--color-white);
}

.NotificationsMenuPopUpContainer .popupArrowDetail {
  width: 50px;
  position: absolute;
  height: 24px;
  overflow: hidden;
  right: 27px;
  top: -16px;
}

.NotificationsMenuPopUpContainer .popupArrowDetail:before {
  content: '';
  width: 50px;
  position: absolute;
  height: 50px;
  -webkit-transform: rotate(45deg);
  top: 12px;
  z-index: 10;
  background: var(--color-white);
  box-shadow: none;
  border: 0;
  border-radius: 5px;
}
