.ErrorBoxContainer {
    width: 100%;
    background-color: #FF000080;
    border-radius: 2px;
    padding: 10px;
    transition-duration: 0.5s;
}

.ErrorBoxText {
    margin-bottom: 0;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--color-white);
    font-weight: normal;
    max-width: 100%;
    text-wrap: wrap;
}

.ErrorBoxHidden {
    opacity: 0;
}