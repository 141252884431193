.CourseEditContainer{
    padding-top: 20px;
}

.AddInstructorWizardContainer .Header {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
}

.AddInstructorWizardContainer .SectionTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    margin-bottom: 15px;
    display: block;
}

.AddInstructorWizardContainer .SaveButton {
    margin-top: 20px;
}

.AddInstructorWizardContainer h1 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: bold;
    color: black;
}

.AddInstructorWizardContainer h3 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: #9CA6AC;
    margin-top: 25px;
}

.AddInstructorWizardContainer .AvatarContainer {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: #1D7777;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.AddInstructorWizardContainer .CurrentInftructorInfo p {
    font-family: var(--font-family);
    font-size: var(--font-size-h5);
    color: black;
    font-weight: bold;
    margin-bottom: 0px;
}

.AddInstructorWizardContainer .CurrentInstructorContainer {
    margin-bottom: 20px;
}

.AddInstructorWizardContainer .AvatarContainer span {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    color: white;
}

[data-column-id="InstructorCheckboxColumn"].rdt_TableCol,
[data-column-id="InstructorCheckboxColumn"].rdt_TableCell {
    max-width: 34px;
    min-width: 34px;
    padding: 5px;
}

.AddInstructorWizardContainer .rdt_TableCol_Sortable
{
    justify-content: center;
    width: 100%;
    padding: 5px;
}

[data-column-id="EmailColumn"].rdt_TableCell,
[data-column-id="RoleColumn"].rdt_TableCell {
    justify-content: center;
}

.InstructorNameColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:15px;
}

.InstructorNameColumn .Initials{
    font-family: var(--font-family);
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    object-fit: cover;
    object-position: center;
    border: 2px solid #AEF600;
    display: flex;
    position: relative;
    color: #fff;
    background-color: #1D7777;
    margin-right: 8px;
    height: 40px;
    width: 40px;
}

.InstructorNameColumn img{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border: 2px solid #AEF600;
    margin-right: 8px;
}



.dataTableContainer{
    max-height: 50vh;
    overflow-y: auto hidden;
}