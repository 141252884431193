.SimplePlayerControlled {
    position: relative;
    background-color: var(--color-black);
    border-radius: 18px;
}

.SimplePlayerControlled .VideoLoading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}