.AnalyticsEmptyState {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-top: 100px;
}

.AnalyticsEmptyState img {
    margin-bottom: 24px;
}

.AnalyticsEmptyState .NoData {
    display: block;
    margin-bottom: 16px;
}

.AnalyticsEmptyState span {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: 500;
    color: var(--neutral-color-7);
}