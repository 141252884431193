.EditorLayoutContainer {
    width: 100%;
    height: 100%;
}

.EditorLayoutContainer .PublishedCourseBadge {
    background-color: #FF0000;
    border-bottom-left-radius: 3px;
    color: white;
    font-size: 10px;
    text-align: end;
    padding: 0px 5px;
    align-self: flex-end;
    font-family: var(--font-family);
}

.EditorColumnsContainer {
    overflow: hidden;
}

.EditorHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
}

.EditorHeader .Section {
    display: flex;
    flex-direction: row;
}

.EditorHeader .Section .Button {
    margin-left: 10px;
}

.EditorHeader .BackButton {
    background-color: #005250;
    padding: 0.4rem 1rem;
    border-radius: 50px;
    border: none;
    outline: none;
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: .9rem;
    font-weight: normal;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.EditorHeader .BackButton:hover {
    background-color: #23a09e;
}

.EditorHeader .Title {
    padding-left: 20px;
    padding-right: 20px;
}

.EditorHeader .Preview {
    margin-right: 6px;
}

.EditorCustomContentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
    background-color: var(--neutral-color-10);
    overflow: auto;
    position: relative;
}

.EditorContentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
    background-color: var(--neutral-color-10);
    opacity: 0.8;
    background-image: radial-gradient(#EEEDED 1px, var(--neutral-color-10) 1px);
    background-size: 10px 10px;
    padding: 30px;
    overflow: auto;
    position: relative;
}

.EditorContentContainer .PublishedCourseBadge {
    position: absolute;
    top: 0;
    right: 0;
}

.EditorPublished {
    border: 3px solid #FF0000;
}

.EditorContentContainer .LoaderContainer {
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: var(--primary-color-3);
    border-radius: 5px;
    display: flex;
    align-self: baseline;
    position: absolute;
    bottom: 10px;
    left: 10px;
    max-height: 30px;
}

.EditorOptionsContainer {
    background-color: var(--color-white);
    height: 80vh;
    width: 100%;
    border-radius: 5px;
}

.EditorSidebar{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 5px;
}

.EditorSidebarContainer {
    background-color: var(--color-white);
    height: 80%;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 200ms ease-out;   
    overflow-y: auto;
    overflow-x: hidden;
}

.EditorOptionsTabs {
    height: 100%;
    padding: 10px;
}

.EditorOptionsTabs .Tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.EditorOptionsTabs .Tabs p {
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--primary-color-1);
    font-weight: normal;
    padding: 6px;
    margin: 0;
}

.EditorOptionsTabs .Tabs .Active {
    border-bottom: 1px solid var(--primary-color-1);
}

.EditorOptionsTabs .TabsContentContainer {
    padding-top: 15px;
    height: 95%;
    overflow-y: auto;
    padding-bottom: 15px;
}


.EditorOptionsEmptyStateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.EditorOptionsEmptyStateContainer span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: #ACACAC;
    text-align: center;
}


.EditorDraggableList {
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditorDraggableList .ListContainer {
    display: flex;
    flex-direction: column;
}

.EditorAddBelowAction {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EditorAddBelowAction img {
    margin-top: 5px;
    margin-bottom: 5px;
}

.EditorAddBelowButton {
    background: none;
    outline: none;
    border: none;
}

.EditorEntryLabel {
    padding: 0.5rem;
    background-color: var(--color-white);
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px var(--neutral-color-10);
}

.EditorEntryLabel p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
    margin-bottom: 0;
}

.EditorCreateFirstEntry {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EditorCreateFirstEntry .Arrow {
    margin-top: 5px;
    margin-bottom: 5px;
}

.EditorContentContainer button {
    border: none;
    outline: none;
    background: none;
}

.EditorOptionsDeleteButton h3 {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: bold;
    color: #555555;
}

.EditorOptionsDeleteButton button {
    background: #EE8783;
    border: none;
    outline: none;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    color: var(--color-white);
    margin-top: 5px;
}

.EditorOptionsFooter {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.EditorOptionsFooter .Button {
    width: 100%;
    margin-bottom: 10px;
}

.EditorHeader .Breadcrumbs {
    padding-left: 10px;
    padding-right: 10px;
}

.FolderNameContainer {
    display: flex;
    flex-direction: row;    
    align-items: center;
}
