.CourseNotPublished {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #F6F6F6;
    padding: 40px;
    border-radius: 10px;
}

.CourseNotPublished .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-h5);
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
}

.CourseNotPublished .Subtitle {
    font-family: var(--font-family);
    font-size: var(--font-size-h6);
    color: #8C8C8C;
    margin-bottom: 15px;
}