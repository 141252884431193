.SubscriptionSettingsContainer {
    padding-top: 20px;
}

.SubscriptionSettingsContainer .SubscriptionPlan {
    max-width: 400px;
}

.SubscriptionSettingsContainer .SubscriptionField {
    margin-top: 20px;
}

.SubscriptionSettingsContainer .UnsubscribeText {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    color: var(--error-color-3);
    margin-bottom: 8px;
    margin-top: 20px;
}

.SubscriptionSettingsContainer .PaymentMethodText {
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    color: black;
    margin-bottom: 8px;
    margin-top: 20px;
}

.ActionsSectionContainer {
    margin-top: 30px;
}

.ActionsSectionContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--neutral-color-7);
    width: 100%;
    margin-bottom: 30px;
}

.InvoicesSectionContainer {
    margin-top: 30px;
}

.InvoicesSectionContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
}

.FeaturesTableModal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000066;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

.FeaturesTableModal .FeaturesTableBody {
    width: 80vw;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
    padding: 25px;
    border-radius: 15px;
}

.FeaturesTableModal .FeaturesTable {
    margin-top: 10px;
}

.FeaturesTableModal .FeaturesTable .StickyHeader {
    top: -25px;
}

.FeaturesTableModal .FeaturesTableBody .Title {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: black;
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
} 

.FeaturesTableModal .CloseButton {
    background: none;
    outline: none;
    border: none;
}