.PreviewPlayerModal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 110;
}

.PreviewPlayerContent {
    position: relative;
}

.PreviewPlayerContent .CloseButton {
    position: absolute;
    top: -40px;
    right: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid var(--accent-color);
    background: rgba(0, 0, 0, 0.59);
}

.PreviewPlayerContent .CloseButton span {
    font-size: var(--font-size-h2);
    color: var(--color-white);
}

.PreviewPlayerContent .CloseButton:hover {
    transform: scale(1.1);
}

.PreviewPlayerContainer {
    width: 50vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PreviewImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
}

.PreviewImageContainer img {
    max-width: 40vw;
    object-fit: contain;
    max-height: 60vh;
}