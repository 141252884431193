.MobileRestrictionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.MobileRestrictionContainer img {
  max-width: 80%;
}

.MobileRestrictionContainer h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.MobileRestrictionContainer span {
  font-family: var(--font-family);
  font-size: var(--font-size-paragraph);
  font-style: normal;
  font-weight: 200;
  line-height: var(--line-height-paragraph);
  text-align: center;
  max-width: 80%;
}

@media (min-width: 300px) and (max-width: 1024px) {
  .MobileRestrictionContainer h2 {
    text-align: center;
  }
}
