.TimeInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TimeInputContainer .LabelTop {
    color: var(--neutral-color-1);
    font-size: 14px;
}

.TimeInputContainer input {
    width: 6.5rem;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
    border: none;
    outline: none;
    background-color: var(--neutral-color-10);
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.TimeInputError {
    border-bottom: 1px solid red;
}

.TimeInputContainer .TimeInputFullWidth {
    width: 100%;
    text-align: start;
    padding-left: 10px;
}

.Invalid input {
    border: 1px solid red;
}

.TimeInputContainer input::placeholder {
    color: #3A3A3A59;
}

.TimeInputContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    color: #B1B1B1;
    font-weight: normal;
    margin-bottom: 0;
}

/* TextInput */
.TimeInputTextContainer {
    display: flex;
    flex-direction: column;
}

.TimeInputTextContainer .LabelTop {
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    color: #555555;
    font-weight: bold;
    margin-bottom: 10px;
}

.TimeInputTextContainer input {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-1);
    font-weight: normal;
    border-bottom: 1px solid #efeae7;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}

.TimeInputTextContainer input::placeholder {
    color: #3A3A3A59;
}

.TimeInputTextContainer p {
    font-family: var(--font-family);
    font-size: 12px;
    color: #B1B1B1;
    font-weight: normal;
    margin-bottom: 0;
}

.TimeInputTextContainer .TimeInputSubtitle {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    color: var(--neutral-color-5);      
}

.TimeInputWarning p 
{
    font-family: var(--font-family);
    color: #FF0000;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 1rem !important;
}