.TipBox {
    display: flex;
    background-color: var(--neutral-color-10);
    border-radius: 20px;
    padding: 14px 18px;
    flex-direction: column;
}

.TipBox p {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    margin-bottom: 0px;
    color: var(--color-black);
    font-weight: 400;
}

.TipBox h6 {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    margin-bottom: 0px;
    color: var(--secondary-color-1);
    font-weight: 600;
}

.TipBox .TipHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    justify-content: flex-start;
}