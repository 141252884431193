.EditVideoSourceContainer {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
}

.EditVideoSourceContainer .ActionsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.EditVideoSourceContainer h3 {
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
    color: var(--primary-color-1);
    margin-top: 20px;
}

.EditVideoSourceContainer .VideoTimelineContainer {
    background-color: #E7ECEF;
    padding: 15px;
    border-radius: 20px;
    flex-direction: row;
    display: flex;
    overflow-x: scroll;
    gap: 10px;
}

.EditVideoSourceContainer .VideoTimelineContainer > * {
    flex: 0 0 auto;
}

.EditVideoSourceContainer .VideoChunk {
    border: 2px solid #005250;
    border-radius: 7.5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
}

.EditVideoSourceContainer .VideoCover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditVideoSourceContainer .VideoCover button {
    border: none;
    outline: none;
    background-color: var(--primary-color-1);
    border-radius: 40px;
    display: block;
    width: 30px;
    height: 30px;
}

.EditVideoSourceContainer .VideoCover span {
    color: white;
    padding: 0;
    margin: 0;
    display: block;
    padding-left: 2px;
}

.EditVideoSourceContainer .VideoChunkOmmited {
    filter: grayscale(1);
    opacity: 0.4;
}

.EditVideoSourceContainer .TimelineIndicatorsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.EditVideoSourceContainer .TimelineIndicatorsContainer span {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
}

.EditVideoSourceContainer .IncludeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.EditVideoSourceContainer .IncludeContainer button {
   border: none;
   outline: none;
   background: none;
   padding: 0;
   margin: 0;
   display: contents;
}

.EditVideoSourceContainer .IncludeContainer span {
    font-size: 18px;
    color: black;
}

.FullChunksPreviewPlayerContainer {
    width: 50vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.NoChunksContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}


.NoChunksContainer p {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
}