.TextAreaInput {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--neutral-color-1);
    font-weight: normal;
    border-bottom: 1px solid #efeae7;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    margin-top: 10px;
    resize: none;
}

.TextAreaInput::placeholder {
    color: var(--neutral-color-7);
}

.TextAreaBoxInput {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background-color: var(--neutral-color-10);
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--neutral-color-1);
    font-weight: normal;
    border: 1px solid var(--neutral-color-10);
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    margin-top: 10px;
    resize: none;
}

.TextAreaContainer .FieldError {
    border-color: var(--error-color-1);
}

.TextAreaHeading {
    font-family: var(--font-family);
    font-size: 12px;
    color: #555555;
    font-weight: bold;
    margin-bottom: 0;
}

.TextAreaEye {
    margin-left: 10px;
}

.TextAreaCharactersCountContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.TextAreaMaxCharacters {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    padding-left: 5px;
    padding-right: 5px;
}

.TextAreaMaxCharacters span {
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    color: var(--neutral-color-7);
    font-weight: normal;
}