.EndingOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .EndingOverlay img {
    width: 70px;
  }
  
  .EndingOverlay::-webkit-scrollbar {
    display: none;
  }
  
  .EndingOverlay .Title {
    font-family: var(--font-family);
    font-size: 2.2rem;
    font-weight: normal;
    color: var(--color-white);
  }
  
  .EndingOverlay .Footer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  
  .EndingOverlay .Footer button {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .EndingOverlay .Description {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: normal;
    color: var(--color-white);
    margin-bottom: 30px;
  }
  
  .EndingOverlay .QuestionsListContainer {
    width: 50%;
  }
  
  .EndingOverlay .QuestionsListContainer .Question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .EndingOverlay .QuestionsListContainer .Question span {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    color: var(--color-white);
  }
  
  .EndingOverlay .QuestionsListContainer .Total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    padding-top: 25px;
    border-top: 1px solid white;
  }
  
  .EndingOverlay .QuestionsListContainer .Total span {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: normal;
    color: var(--color-white);
  }
  
  .EndingOverlay .QuestionsListContainer .Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .EndingOverlay .Success {
    color: var(--success-color-6);
  }
  
  .EndingOverlay .Fail {
    color: var(--error-color-1);
  }


  @media only screen and (max-width: 767px) {
    .EndingOverlay img{
        width: 12%;
    }
    .EndingOverlay .Title{
        font-size: 1.3rem;
    }
    .EndingOverlay .Footer{
        margin-top: 0.5rem;
    }
    .EndingOverlay .Description{
        font-size: 1rem;
        margin-bottom:0.5rem;
    }
    .EndingOverlay .QuestionsListContainer{
        width:70%;
    }
    .EndingOverlay .QuestionsListContainer .Total span{
        font-size: 1rem;
    }
    .EndingOverlay .QuestionsListContainer .Actions{
        margin-top:.5rem;
        justify-content: center;
    }
  }


  @media only screen and (max-width: 400px) {
    .EndingOverlay {
      margin:2rem;
    }
  }
  