
.CoursesChartContainer {
    display: flex;
    flex-direction: row;
}

.CoursesChart {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CoursesChart .TotalCourses {
    position: absolute;
}

.CoursesChart span {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 400;
    color: var(--neutral-color-3);
    line-height: 16px;
    text-align: center;
    display: block;
    margin-top: 14px;
}

.CoursesChart p {
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    font-weight: 700;
    color: var(--color-black);
    line-height: 32px;
    text-align: center;
    margin-top: 6px;
}

.CoursesChartLegend {
    margin-left: 24px;
}

.CoursesChartLegend span {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.CoursesChartLegend table {
    width: 100%;
    border-top: 1px solid var(--neutral-color-9);
    margin-top: 6px;
}

.CoursesChartLegend table td {
    padding: 4px 0px;
}

.CoursesChartLegend .Value {
    text-align: end;
}

.CoursesChartLegend .Value span {
    font-weight: 500;
}

.CoursesChartLegend .LegendIndicator {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 6px;
}

.CoursesAndCardsSection {
    display: flex;
    flex-direction: column;
    gap: 50px;
    flex: 2;
}