.AddJumpToWizardContainer .Header {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-color-1);
    margin-bottom: 40px;
}

.AddJumpToWizardContainer .Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.AddJumpToWizardContainer .DropdownWhitespeace {
    margin-top: 150px;
}