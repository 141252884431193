.CourseAnalyticsLearnersContainer {
    padding: 8px 22px;
}

.CourseAnalyticsLearnersContainer .BreadcrumbsContainer {
    display: flex;
    flex-direction: row;
}

.CourseAnalyticsLearnersContainer .BreadcrumbsContainer .TextButton {
    font-size: var(--font-size-3);
}

.CourseAnalyticsLearnersContainer .BreadcrumbsContainer .icon-arrow-narrow-left {
    font-size: 16px;
}

.CourseAnalyticsLearnersContainer .PageTitleContainer h1 {
    font-size: var(--font-size-h2);
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CourseAnalyticsLearnersContainer .HeadingSection {
    display: flex;
    flex-direction: row;
    gap: 66px;
    margin-left: 36px;
    margin-right: 36px;
}

.CourseAnalyticsLearnersContainer .GraphSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CourseAnalyticsLearnersContainer .AnalyticsCardsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 24px;
}

.CourseLearnersAnalyticsTableContainer {
    border: 1px solid var(--neutral-color-9);
    border-radius: 16px;
    overflow: hidden;
    margin-top: 14px;
    flex: 4;
}

.CourseLearnersAnalyticsTable {
    width: 100%;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
}

.CourseLearnersAnalyticsTable th {
    background-color: var(--neutral-color-10);
    font-family: var(--font-family);
    font-size: var(--font-size-5);
    font-weight: 600;
    padding: 12px 8px;
    border-bottom: 1px solid var(--neutral-color-9);
    text-align: center;
}

.CourseLearnersAnalyticsTable td {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 400;
    padding: 6px 8px;
    border-bottom: 1px solid var(--neutral-color-9);
    text-align: center;
}

.CourseLearnersAnalyticsTable .LearnerColumn {
    text-align: start;
}


.CourseLearnersAnalyticsTable .LearnerNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 160px;
}

.CourseLearnersAnalyticsTable .LearnerNameContainer img {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 8px;
}

.CourseLearnersAnalyticsTable .LearnerNameContainer .AvatarPlaceholder {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color-1);
    color: var(--color-white);
}

.CourseLearnersAnalyticsTable .StatusCompleted {
    padding: 4px 9px;
    border: 1px solid var(--success-color-3);
    background: var(--success-color-10);
    border-radius: 18px;
    font-size: var(--font-size-5);
    margin-bottom: 0;
    text-align: center;
    width: fit-content;
    white-space: nowrap;
}

.CourseLearnersAnalyticsTable .StatusProgress {
    padding: 4px 9px;
    border: 1px solid #3B82F6;
    background: #E5F1FF;
    border-radius: 18px;
    font-size: var(--font-size-5);
    margin-bottom: 0;
    text-align: center;
    width: fit-content;
    white-space: nowrap;
}

.CourseLearnersAnalyticsTable .StatusNotStarted {
    padding: 4px 9px;
    border: 1px solid var(--neutral-color-8);
    background: var(--color-white);
    border-radius: 18px;
    font-size: var(--font-size-5);
    margin-bottom: 0;
    text-align: center;
    width: fit-content;
    white-space: nowrap;
}

.CourseAnalyticsLearnersContainer .Duration {
    font-weight: 500;
    text-align: center;
}

.CourseAnalyticsLearnersContainer .ScoreSuucess {
    color: var(--success-color-3);
    font-weight: 600;
    text-align: center;
}

.CourseAnalyticsLearnersContainer .ScoreFail {
    color: var(--error-color-3);
    font-weight: 600;
    text-align: center;
}

.CourseAnalyticsLearnersContainer .ProgressChartContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.CourseAnalyticsLearnersContainer .ProgressChartContainer .Amount {
    position: absolute;
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    font-weight: 600;
    color: var(--neutral-color-2);
}

.CourseLearnersAnalyticsTable .icon-eye {
    font-size: 24px;
    color: var(--primary-color-3);
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: block;
}

.CourseLearnersAnalyticsTable .icon-eye:hover {
    transform: rotate(20deg);
}

.CourseAnalyticsLearnersContainer .SearchScoreContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;
}

.CourseAnalyticsLearnersContainer .PassingScoreContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.CourseAnalyticsLearnersContainer .PassingScoreContainer .InfoText {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: 400;
    color: var(--neutral-color-3);
}

.CourseAnalyticsLearnersContainer .PassingScoreContainer .ScoreText {
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: 500;
    color: var(--color-black);
}

.CourseAnalyticsLearnersContainer .SearchExport {
    display: flex;
    gap: 20px;
}

.CourseAnalyticsLearnersContainer .ExportButton {
    border-radius: 12px;
    padding: 10px 14px;
}

.CourseAnalyticsLearnersContainer .TableAndDetails {
    display: flex;
    gap: 26px;
}

.LearnerAnalyticsDetailsContainer {
    border-radius: 16px;
    border: 1px solid var(--primary-color-5);
    background: var(--neutral-color-10);
    margin-top: 14px;
    flex: 2;
    padding: 31px 23px;
    position: relative;
}

.LearnerAnalyticsDetailsContainer .AvatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LearnerAnalyticsDetailsContainer .AvatarPlaceholder {
    width: 86px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 86px;
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    color: var(--color-white);
    background-color: var(--primary-color-3);
    margin-bottom: 11px;
}

.LearnerAnalyticsDetailsContainer img {
    width: 86px;
    height: 86px;
    border-radius: 86px;
    margin-bottom: 11px;
}

.LearnerAnalyticsDetailsContainer .LearnerName {
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    color: var(--color-black);
    font-weight: 500;
}

.LearnerAnalyticsDetailsContainer .QuickStatsContainer {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-top: 32px;
}

.LearnerAnalyticsDetailsContainer .QuickStat {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LearnerAnalyticsDetailsContainer .QuickStatsContainer p {
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--font-size-title-h1);
    color: var(--neutral-color-1);
    font-weight: 700;
    margin-bottom: 10px;
}

.LearnerAnalyticsDetailsContainer .QuickStatsContainer span {
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--color-black);
    font-weight: 400;
}

.LearnerAnalyticsDetailsContainer .LearnersTableContainer {
    border: 1px solid var(--neutral-color-9);
    border-radius: 16px;
    overflow: hidden;
    margin-top: 24px;
}

.LearnerAnalyticsDetailsContainer table {
    width: 100%;
}

.LearnerAnalyticsDetailsContainer th {
    background-color: var(--neutral-color-9);
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: var(--color-black);
    padding: 16px 12px;
}

.LearnerAnalyticsDetailsContainer td {
    border-bottom: 1px solid var(--neutral-color-9);
    font-family: var(--font-family);
    font-size: var(--font-size-6);
    color: var(--color-black);
    padding: 12px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.LearnerAnalyticsDetailsContainer .CloseButton {
    border: none;
    outline: none;
    background: none;
    font-size: 24px;
    color: var(--primary-color-3);
    position: absolute;
    top: 10px;
    right: 10px;
}