.AddFreeformOverlay {
    display: flex;
    flex-direction: row;
}

.AddFreeformOverlay .PlayerContainer {
    width: 50vw;
    position: relative;
}

.AddFreeformOverlay .SectionHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.AddFreeformOverlay .OptionsContainer {
    width: 18vw;
}

.AddFreeformOverlay .OptionsContent {
    padding-left: 15px;
}

.AddFreeformFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.AddFreeformOverlay .OptionsInput {
    margin-bottom: 15px;
}

.AddFreeformOverlay .OptionsContent .VisibleCheck {
    margin-top: 15px;
}

.AddFreeformOverlay .ImageSourceContainer {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.AddFreeformOverlay .ImageSourceContainer img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
}

.AddFreeformOverlay .ImageSourceContainer span {
    background-size: cover;
    filter: blur(100px);
    position: absolute;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1.4);
}