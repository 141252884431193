.Button {
    background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
}

.Button .Icon {
    display: table-cell;
    padding-right: 5px;
}

.Button .LoadingContianer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ButtonInfoText {
    color: #000;
    font-family: var(--font-family);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: 0px;
}

.ActionButton {
    background-color: var(--primary-color-1);
    color: var(--color-white);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 10px 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--primary-color-1);
}

.ActionButton:hover {
	background-color: var(--primary-color-3);
    color: var(--color-white);
    border: 1px solid var(--primary-color-3);
}

.TabButton {
    background-color: transparent;
    color: var(--neutral-color-7);
    font-family: var(--font-family);
    font-size: .8rem;
    font-weight: normal;
    line-height: 14px !important;
    padding: 0.6rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid var(--neutral-color-7);
}

.TabButton:hover {
	opacity: 0.8;
}

.TabButtonActive {
    background-color: transparent;
    color: 'black';
    font-family: var(--font-family);
    font-size: .8rem;
    font-weight: normal;
    line-height: 14px !important;
    padding: 0.6rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid var(--primary-color-1);
}

.TabButtonActive:hover {
	opacity: 0.6;
}

.DangerButton {
    background-color: var(--error-color-3);
    color: white;
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: .8rem;
    font-weight: normal;
    line-height: 14px !important;
    padding: 0.4rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--error-color-3);
}

.DangerButton:hover {
	background-color: var(--error-color-5);
    color: white;
}

.ButtonHollow {
    color: var(--primary-color-1);
    background-color: var(--color-white);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 10px 14px;
    border: 1px solid var(--primary-color-1);
}

.ButtonHollow:hover {
	color: var(--color-white);
    background-color: var(--primary-color-3);
    border: 1px solid var(--primary-color-3);
}

.BoxButton {
    color: var(--color-white);
    background-color: var(--secondary-color-1);
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 0.6rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--secondary-color-1);
}

.BoxButton:hover {
	color: var(--color-white);
    background-color: var(--primary-color-3);
    border: 1px solid var(--primary-color-3);
}

.ButtonBoxHollow {
    color: var(--primary-color-1);
    background-color: var(--color-white);
    border-radius: 8px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 10px 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--primary-color-1);
}

.ButtonBoxHollow:hover {
	color: var(--color-white);
    background-color: var(--primary-color-3);
    border: 1px solid var(--primary-color-3);
}

.ActionBoxButton {
    color: var(--color-white);
    background-color: var(--primary-color-1);
    border-radius: 3px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 0.6rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--primary-color-1);
}

.ActionBoxButton:hover {
	color: var(--primary-color-1);
    background-color: var(--secondary-color-10);
}


.TextButton {
    color: var(--neutral-color-1);
    background-color: transparent;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 10px 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
}

.TextButton:hover {
	color: var(--secondary-color-3);
    background-color: transparent;
}

.TextDangerButton {
    color: var(--error-color-1);
    background-color: transparent;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: 500;
    line-height: 14px !important;
    padding: 0.4rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
}

.TextDangerButton:hover {
	color: var(--error-color-1);
    background-color: transparent;
}

.AuthActionButton {
    color: var(--color-white);
    background-color: var(--primary-color-1);
    border-radius: 50px;
    font-family: var(--font-family);
    font-size: var(--font-size-3);
    font-weight: 500;
    line-height: 14px !important;
    padding: 1rem 1rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--primary-color-1);
}

.AuthActionButton:hover {
	color: var(--primary-color-1);
    background-color: var(--secondary-color-1);
}


.Button:disabled {
    border: 1px solid #E2E2E2;
    background-color: #F6F6F680;
    color: #97979780 !important;
    cursor: not-allowed;
}