.IconButton {
    border: none;
    outline: none;
    padding: 6px;
    background: none;
    width: 36px;
    height: 36px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.IconButton:hover {
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity:.5;
    cursor:pointer;
}

.IconButton img {
    max-width: 100%;
}

.IconButton span {
    font-size: 1.1rem;
    color:var(--primary-color-3);
}

.IconButton span.icon-typeAssign-Instructor {
    font-size: 1.4rem;
}

.IconButton span.icon-typeTrash {
    color:var(--error-color-1);
}

.IconButtonDisabled {
    opacity: 0.5;
}