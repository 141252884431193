/* General */
.AiAssistantContainer {
  padding: 30px 38px;
  overflow-y: hidden;
  max-height: 100%;
}

.AiAssistantTitleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.AiAssistantTitleContainer h1 {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: var(--font-size-title-h1);
  color: var(--neutral-color-1);
  margin-bottom: 0;
  margin-left: 10px;
}

.AiAssistantTitleContainer h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size-3);
  color: var(--neutral-color-5);
  margin-bottom: 0;
  padding-left: 10px;
}

.AiAssistantContainer .CreateButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  padding: 14px;
}

.AiAssistantContainer .CreateButton span{
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AiAssistantContainer .CreateButton span.icon-plus {
  margin-right: 0.2rem;
  font-size: 20px;
}

.AiAssistantContainer .AssistantsList {
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow-y: scroll;
  overflow-y: scroll;
  height: 80vh;
  overflow-x: hidden;
  padding-bottom: 20px;
  max-height: 100%;
  margin-top: 30px;
}

.AiAssistantContainer .AssistantsList .AssistantCol {
  padding-bottom: calc(var(--bs-gutter-x)* .5);
  padding-top: calc(var(--bs-gutter-x)* .5);
}
/* General */

/*Assistant Card*/

.AssistantCardContainer {
  display: flex;
  height: 100%;
  position: relative;
}

.AssistantCard {
  border: 1px solid var(--neutral-color-9);
  border-radius: 4px;
  background: var(--color-white);
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.AssistantCard .CardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.AssistantCard .ImageContainer {
  background: var(--gradient-background);
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1.77;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssistantCard .PlaceholderLogo {
  padding-bottom: 21px;
  padding-left: 26px;
}

.AssistantCard .FileOptionsPosition {
  position: absolute;
  top: 9px;
  right: 11px;
}

.AssistantCard .ImageContainer img {
  object-fit: cover;
  max-width: 100%;
}

.AssistantCard .InfoContainer {
  padding: 14px 12px 9px 12px;
}

.AssistantCard .InfoContainer p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--color-black);
  font-weight: 500;
  margin-bottom: 4px;
}

.AssistantCard .Description {
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  color: var(--neutral-color-6);
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 20px;
}

.AssistantCard .Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--neutral-color-9);
  padding: 12px 10px;
}

.AssistantCard .Footer .AssignedOpener {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.AssistantCard .Footer .AssignedOpener:hover {
  opacity: 0.5;
}

.AssistantCard .Footer .AssignedOpener span {
  font-family: var(--font-family);
  font-size: var(--font-size-4);
  color: var(--success-color-2);
  font-weight: 600;
  display: block;
}

.AssistantCard .Footer .AssignedOpener .icon-chevron-up {
  font-size: var(--font-size-3);
  display: block;
  margin-left: 6px;
  transform: rotate(180deg);
}

.AssistantCard .Footer .AssignedOpenerOpen .icon-chevron-up {
  transform: rotate(360deg);
}

.AssistantCardContainer .AssignmentsPopup {
  list-style: none;
  margin: 0;
  padding: 8px;
  margin-top: 10px;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 60%;
  left: 0;
  z-index: 10;
  border: 1px solid var(--neutral-color-9);
  border-radius: 4px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--color-white);
}

.AssistantCardContainer .AssignmentsPopup p {
  font-family: var(--font-family);
  color: var(--color-black);
  font-size: var(--font-size-4);
  font-weight: 400;
  margin-bottom: 8px;
  line-height: 20px;
}

/*Assistant Card*/

/*Assistant Options*/
.AssistantCardContainer .AssistantOptionsPosition {
  position: absolute;
  top: 9px;
  right: 11px;
}

.AssistantCardContainer .AssistantOptions {
  position: relative;
}

.AssistantCardContainer .AssistantOptions .Opener {
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 80px;
  background: var(--color-white);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.AssistantCardContainer .AssistantOptions .LoadingContainer {
  position: absolute;
  top: 3px;
}

.AssistantCardContainer .AssistantOptions .Opener:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.08));
}

.AssistantCardContainer .AssistantOptions .Opener span {
  font-size: 24px;
}

.AssistantCardContainer .AssistantOptions .Options {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: var(--color-white);
  z-index: 1;
  border: 1px solid var(--neutral-color-8);
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  width: max-content;
  min-width: 100px;
}

.AssistantCardContainer .AssistantOptions .Option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  font-family: var(--font-family);
  font-size: var(--font-size-5);
  font-weight: 500;
  color: var(--color-black);
  cursor: pointer;
}

.AssistantCardContainer .AssistantOptions .DeleteOption {
  color: var(--error-color-4);
  border-top: 1px solid var(--neutral-color-9);
}

.AssistantCardContainer .AssistantOptions .DeleteOptionDisabled {
  color: var(--neutral-color-7);
  border-top: 1px solid var(--neutral-color-9);
}

.AssistantCardContainer .AssistantOptions .Option:hover {
  background-color: var(--primary-color-9);
}

.AssistantCardContainer .AssistantOptions .OptionActive {
  background-color: var(--primary-color-9);
}
/*Assistant Options*/

/*Empty State*/
.AssistantEmptyStateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.AssistantEmptyStateContainer .EmptyStateIcon {
  font-size: 60px;
  color: var(--neutral-color-8);
  margin-bottom: 33px;
  margin-left: 14px;
}

.AssistantEmptyStateContainer p {
  font-family: var(--font-family);
  font-size: var(--font-size-3);
  color: var(--neutral-color-7);
  font-weight: 500;
}
