.LibraryFilesInputContainer {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid var(--neutral-color-8);
    background: var(--color-white);
    padding: 8px 8px 8px 14px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.LibraryFilesInputContainer .FilesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.LibraryFilesInputContainer .FileItem {
    border-radius: 4px;
    max-width: 220px;
    padding: 5px 5px 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LibraryFilesInputContainer .FileItem .FileIndicator {
    margin-right: 4px;
}

.LibraryFilesInputContainer .FileItem .RemoveIndicator {
    margin-left: 4px;
    cursor: pointer;
}

.LibraryFilesInputContainer .FileItem p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-size-6);
    font-family: var(--font-family);
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 0;
}

.LibraryFilesInputContainer .BrowseSection {
    padding-left: 8px;
    border-left: 1px solid var(--neutral-color-8);
}