.OrgDataForm .Input {
    margin-bottom: 20px;
    flex: 1
}

.OrgDataForm .Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.OrgDataForm .ScrollPadding {
    margin-bottom: 60px;
}

.colorPickContainer{
    background: #e9e9e9;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;
}

.colorPickContainer .colorPickClose{
    align-self: flex-end;
    padding: 0.5rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.colorPickContainer .colorPickClose:hover{
    cursor:pointer;
    opacity: .5;
}

.colorPickContainer .react-colorful{
    margin-bottom:0
}

.OrgDataForm .ColorResetButton {
    position: absolute;
    right: 25px;
    top: 35px;
}

.OrgDataForm .ColorTextInputContainer {
    position: relative;
}