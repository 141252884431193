.CourseAccessGateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 10vh;
}

.CourseAccessGateContainer h1 {
    font-size: var(--font-size-h1);
    font-family: var(--font-family);
    font-weight: bold;
}

.CourseAccessGateContainer h3 {
    font-size: var(--font-size-h3);
    font-family: var(--font-family);
    font-weight: normal;
    margin-top: 18px;
}

.CourseAccessGateContainer p {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    font-weight: normal;
    margin-top: 18px;
    margin-bottom: 5px;
}

.CourseAccessGateContainer .Feature {
    font-weight: bold;
}

.CourseAccessGateContainer a {
    font-size: var(--font-size-h5);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--primary-color-3);
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
}