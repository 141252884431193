.courses-card {
  border: 2px solid #031e1e30;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.courses-header {
  position: relative;
  margin-bottom: 1rem;
  display:flex;
  justify-content: center;
}

.courses-organization_name {
  margin: 0.5rem;
  color: rgb(131, 131, 131);
}

.courses-lessons_container {
  margin-left: 0.5rem;
  display: flex; 
  align-items: center;
  margin-bottom:1rem;
}

.courses-lessons_container img{
  margin-right: 0.5rem;
}

.courses-lessons_container p{
  margin: 0;
  font-weight: 500;
  font-size: .8rem;
}

.courses-organization {
  position: absolute;
  right: 10px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color-1);
  bottom: -20px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  background-color: var(--neutral-color-1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.courses-card_container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}
.courses-thumb {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 150px;
  background-color: var(--neutral-color-10);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.courses-tag_container {
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 90%;
}

.courses-tag_container .courses-tag {
  display: block;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}

.courses-tag_container .courses-tag.tag-math {
  background-color: var(--primary-color-6);
  color: var(--color-white);
}

.courses-tag_container span {
  display: block;
}

.courses-title {
  width: 90%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: normal;
  font-size: 1rem;
  margin: 0.5rem;
}

.courses-progress_container {
  margin-left: 0.5rem;
}

.courses-lessons_container {
  margin-left: 0.5rem;
}

@media only screen and (min-width: 767px) and (max-width: 1050px) {
  .courses-tag_container .courses-tag {
    max-width: 83%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
