.AddImageSourceContainer .ImageSource {
    width: 50vw;
    height: calc(50vw  / 1.77);
    object-fit: contain;
    background-color: gray;
}

.AddImageSourceContainer .AddImageOptionsContainer {
    margin-left: 15px;
}

.AddImageSourceContainer .DurationInput {
    align-items: baseline;
    margin-top: 10px;
}

.AddImageSourceContainer .ImageOptionsHeader {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    color: var(--neutral-color-1);
    text-align: center;
}

.AddImageOptionsContainer .MaxDuration {
    font-size: var(--font-size-6);
    font-family: var(--font-family);
    color: #9E9E9E;
}