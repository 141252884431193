.CreateOrganizationModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(10px);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000066;
}

.CreateOrganizationContainer {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 40vw;
}

.CreateOrganizationContainer .ErrorBox {
  margin-bottom: 15px;
}

.CreateOrganizationContainer .Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.CreateOrganizationContainer .Header h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: bold;
  color: var(--neutral-color-1);
  margin-bottom: 0;
}

.CreateOrganizationContainer .Input {
  margin-bottom: 20px;
}

.CreateOrganizationContainer .Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.UserMenuPopUpContainer ul li.createOrganizationButtonContainer {
  display: none;
}

@media only screen and (min-width: 1025px) {
  .UserMenuPopUpContainer ul li.createOrganizationButtonContainer {
    display: block;
  }
}
