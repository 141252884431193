.LessonGeneration .LessonGenHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LessonGeneration .LessonGenHeading p {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    font-weight: 600;
    margin-bottom: 0;
    line-height: 36px;
    color: var(--primary-color-1);
}

.LessonGeneration .CustomizeTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-1);
    font-weight: 500;
    margin-bottom: 6px;
    color: var(--secondary-color-1);
    margin-top: 33px;
}

.LessonGeneration .LessonGenVideoTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-2);
    color: var(--neutral-color-1);
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 27px;
}

.LessonGeneration .LessonGenVideoSubTitle {
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    color: var(--neutral-color-5);
    font-weight: 400;
    margin-bottom: 20px;
}

.LessonGeneration .LessonGenPassingScore {
    width: 50%;
}

.LessonGeneration .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 4px;
    margin-top: 16px;
}
