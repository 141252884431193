.ChoiceOverlay {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.ChoiceOverlay::-webkit-scrollbar {
  display: none;
}

.ChoiceOverlay p {
  font-family: var(--font-family);
  font-size: calc(100vh / 40);
  margin-bottom: 0.5rem;
  font-weight: normal;
  color: var(--color-white);
  margin-bottom: 25px;
}

.ChoiceOverlay img {
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-width: 20%;
}

.ChoiceOverlay img:hover {
  border: 2px solid var(--secondary-color-3);
  transform: scale(.99) translateY(-2px);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.ChoiceOverlay .TextChoice {
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  border: 2px solid transparent;
  min-height: 100px;
  min-width:100px;
  margin:0.5rem;
}

.ChoiceOverlay .TextChoice:hover {
  border: 2px solid var(--secondary-color-3);
  transform: scale(.96) translateY(-2px);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.ChoiceOverlay .TextChoice span {
  font-family: var(--font-family);
  font-size: calc(100vh / 40);
  font-weight: normal;
  color: var(--neutral-color-1);
}

.ChoiceOverlay .AnswersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ChoiceOverlay p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .ChoiceOverlay {
    padding: 0.5rem;
  }
}
