.SettingsContainer {
    padding-top: 20px;
    padding-bottom: 20px;
}

.SettingsContainer .TabsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.SettingsContainer .TabsContainer a {
    background: none;
    outline: none;
    border: none;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: var(--font-family);
    font-size: var(--font-size-4);
    font-weight: normal;
    color: var(--neutral-color-6);
    border-bottom: 2px solid var(--neutral-color-10);
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.SettingsContainer .TabsContainer a:hover {
    border-bottom: 2px solid var(--primary-color-3);
    color:var(--primary-color-3);
  }

.SettingsContainer .TabsContainer .ActiveButton {
    color: var(--neutral-color-1);
    border-bottom: 2px solid var(--primary-color-3);

}

.SettingsContainer .TabsContainer .FillBorder {
    display: flex;
    flex: 1;
    border-bottom: 2px solid var(--neutral-color-10);
}