/* navigation */
.navbar {
    background-color: rgba(25, 28, 33, 1) !important;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
    font-family: Montserrat !important;
  }

  .navbar.scrolled {
    background-color: rgba(26, 28, 33, 0.9) !important; /* Cambia este color según lo que desees */
  }
  
  .navbar .nav-link.login-button {
    color: var(--accent-color);
  }
  
  .navbar #login:hover {
    border-color: #0e4b47;
    -webkit-box-shadow: 0 5px 15px 0 rgba(43, 137, 130, 0.3);
    box-shadow: 0 5px 15px 0 rgba(43, 137, 130, 0.3);
  }
  
  .navbar #free-signup {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar #free-signup:hover {
    color: var(--accent-color) !important;
    -webkit-box-shadow: 0 5px 15px 0 rgba(43, 137, 130, 0.3);
    box-shadow: 0 5px 15px 0 rgba(43, 137, 130, 0.3);
  }
  
  .navbar > .container-fluid {
    justify-content: flex-end;
  }
  
  .navbar > .container-fluid .navbar-brand {
    margin: 0 auto 0 0;
  }
  
  .navbar > .container-fluid .navbar-brand img {
    width: 100%;
    max-width: 100px;
  }
  
  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active {
    border: 0;
    box-shadow: none;
  }
  
  .navbar-collapse.collapse.show {
    background-color: rgba(25, 28, 33, 1) !important;
  }
  
  .navbar .nav-link {
    color: #fff;
    padding: 1.3rem 2rem;
  }
  .navbar #login {
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  
  /* tablet and above*/
  @media (min-width: 769px) {
    .navbar #login {
      display: block;
      opacity: 1;
      border: 1px solid #d5d7da;
      border-radius: 14px;
      padding-left: 2rem;
      padding-right: 2rem;
      min-height: 42px;
      max-height: 52px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: #fff !important;
    }
    .navbar #free-signup {
      background: linear-gradient(317.42deg, #04504e -11.84%, #022221 110.79%);
      border-radius: 14px;
      color: #fff !important;
      max-height: 52px;
      min-height: 42px;
      display: flex;
      align-items: center;
    }
  
    .navbar {
      padding: 1rem 8rem;
    }
  
    .navbar > .container-fluid .navbar-brand {
      margin-right: 2rem;
    }
  }
  
  @media (min-width: 1000px) {
    .navbar .nav-link.login-button {
      display: none !important;
    }
  }
  /* tablet and above*/
  @media (min-width: 1024px) {
    /* navigation */
    .navbar {
      padding: 1rem 3rem !important;
    }
    .navbar > .container-fluid {
      justify-content: center;
      padding: 0;
    }
    .navbar .nav-link {
      padding: 0.5rem;
    }
  }
  /* tablet and above*/
  
  /* desktop and above */
  @media (min-width: 1280px) {
    .navbar {
      background-color: transparent !important;
      transition: background-color 0.5s ease-in-out; /* Transición suave */
      padding: 1rem 6.4rem !important;
    }
  
    .navbar{
      padding: 1rem 6.4rem;
    }
  }
  
  
  /* 1920 and above*/
  @media (min-width: 1800px) {
      .navbar {
        padding: 1rem 12rem !important;
      }
    }
    /* 1920 and above*/
    